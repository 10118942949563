import React, { useState, useRef, useEffect } from "react"
import { Frame, addPropertyControls, ControlType } from "framer"
import { motion } from "framer-motion"
import Botpoison from "@botpoison/browser"

import {
    CheckCircle,
    Warning,
    Eye,
    EyeSlash,
    CaretDown,
    ArrowLeft,
    PaperPlaneTilt,
    Envelope,
    Person,
    Globe,
    Lock,
    Pencil,
    X,
    Info,
    Question,
    AddressBook,
    Briefcase,
    At,
    CalendarBlank,
    Clipboard,
    EnvelopeSimple,
    House,
    Notebook,
    User,
    FilePlus,
    Paperclip,
    UploadSimple,
    CloudArrowUp,
} from "phosphor-react"

const iconsMap = {
    "address-book": AddressBook,
    at: At,
    briefcase: Briefcase,
    "calendar-blank": CalendarBlank,
    clipboard: Clipboard,
    envelope: Envelope,
    "envelope-simple": EnvelopeSimple,
    house: House,
    person: Person,
    globe: Globe,
    lock: Lock,
    user: User,
    pencil: Pencil,
    x: X,
    notebook: Notebook,
    info: Info,
    question: Question,
    "check-circle": CheckCircle,
    warning: Warning,
    eye: Eye,
    "eye-slash": EyeSlash,
    "caret-down": CaretDown,
    "arrow-left": ArrowLeft,
    "paper-plane-tilt": PaperPlaneTilt,
    "file-plus": FilePlus,
    paperclip: Paperclip,
    "upload-simple": UploadSimple,
    "cloud-arrow-up": CloudArrowUp,
}

/*
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 * @framerDisableUnlink
 */

export function MagicFormPro(props: Props) {
    const [formValues, setFormValues] = React.useState({})
    const [validationErrors, setValidationErrors] = React.useState({})
    const [inputValues, setInputValues] = React.useState({})
    const [toastVisible, setToastVisible] = useState(false)
    const [fieldActive, setFieldActive] = useState({})
    const [showPassword, setShowPassword] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [loadingDots, setLoadingDots] = useState(".")
    const [isActuallySubmitting, setIsActuallySubmitting] = useState(false)
    const [isHovered, setIsHovered] = useState(false)
    const [activeHoverField, setActiveHoverField] = useState(null)
    const [windowWidth, setWindowWidth] = React.useState(0) // Set initial state to 0
    const [currentStep, setCurrentStep] = useState(1)
    const isHigherThanStep1 = currentStep > 1
    const [stepHistory, setStepHistory] = useState([1]) // Initialize with the first step as the
    const [fileValidity, setFileValidity] = useState({ files: false })
    const [selectedFiles, setSelectedFiles] = useState({})
    const [isFileHovered, setIsFileHovered] = useState({})
    const [triggeredConditions, setTriggeredConditions] = useState({})
    const [hoveredIndex, setHoveredIndex] = React.useState(null)
    const fileInputRefs = useRef({})

    const botpoison = new Botpoison({
        publicKey: "pk_e315f2ef-c1cb-4b11-aa31-7e46a704ae31",
    })

    // Example for a specific field named 'fieldName

    React.useEffect(() => {
        // Ensure that the testStep prop exists and is a valid step before updating
        if (
            props.testStep &&
            props.testStep >= 1 &&
            props.testStep <= getTotalSteps()
        ) {
            setCurrentStep(props.testStep)
        }
    }, [props.testStep])

    React.useEffect(() => {
        // Now we're in the browser, we can safely access `window`
        setWindowWidth(window.innerWidth)

        const handleResize = () => setWindowWidth(window.innerWidth)

        window.addEventListener("resize", handleResize)

        // Don't forget to clean up the event listener on component unmount
        return () => window.removeEventListener("resize", handleResize)
    }, []) // Empty dependency array means this effect runs once on mount

    const handleIconClick = () => {}
    const handleFieldActive = (fieldName, isActive) => {
        setFieldActive((prevState) => ({
            ...prevState,
            [fieldName]: isActive,
        }))
    }

    function RangePicker({ name, value, onChange }) {
        const min = props.inputStyle.rangePicker.min
        const max = props.inputStyle.rangePicker.max
        const defaultValue = props.inputStyle.rangePicker.defaultValue
        const trackRef = React.useRef(null)
        const knobWidth = props.inputStyle.rangePicker.knobSize // Knob width in pixels
        const [trackHeight, setTrackHeight] = useState(
            props.inputStyle.rangePicker.trackHeight
        ) // Initialize to 0 or a default value

        const verticalOffset = (trackHeight - knobWidth) / 2
        trackHeight

        useEffect(() => {
            if (trackRef.current) {
                const newTrackHeight =
                    trackRef.current.getBoundingClientRect().height
                setTrackHeight(newTrackHeight)
            }
        }, [])
        useEffect(() => {
            if (value < min || value > max) {
                onChange(name, defaultValue)
            }
        }, [min, max, defaultValue])

        const handleClick = (e) => {
            const trackLeft = trackRef.current.getBoundingClientRect().left
            const trackWidth =
                trackRef.current.getBoundingClientRect().width - knobWidth
            const newPosition = e.clientX - trackLeft - knobWidth / 2
            let newValue =
                min +
                (max - min) *
                    (Math.min(Math.max(newPosition, 0), trackWidth) /
                        trackWidth)

            newValue = Math.round(newValue) // Round to the nearest integer
            onChange(name, newValue)
        }

        const handleMouseDown = (e) => {
            const trackLeft = trackRef.current.getBoundingClientRect().left
            const trackWidth =
                trackRef.current.getBoundingClientRect().width - knobWidth

            const handleMouseMove = (e) => {
                let newPosition = e.clientX - trackLeft
                newPosition = Math.min(Math.max(newPosition, 0), trackWidth) // Keep within bounds
                let newValue = min + (max - min) * (newPosition / trackWidth)
                newValue = Math.round(newValue)
                onChange(name, newValue)
            }

            const handleMouseUp = () => {
                window.removeEventListener("mousemove", handleMouseMove)
                window.removeEventListener("mouseup", handleMouseUp)
            }

            window.addEventListener("mousemove", handleMouseMove)
            window.addEventListener("mouseup", handleMouseUp)
        }

        const position = ((value - min) / (max - min)) * 100

        return (
            <div
                ref={trackRef}
                style={{
                    width: "100%",
                    height: `${trackHeight}px`,
                    borderRadius: "8px",
                    background: props.inputStyle.rangePicker.trackColor,
                    position: "relative",
                    cursor: "pointer",
                }}
                onClick={handleClick}
            >
                <div
                    style={{
                        width: `${knobWidth}px`,
                        height: `${knobWidth}px`,
                        background: props.inputStyle.rangePicker.knobColor,
                        borderRadius: "50%",
                        position: "absolute",
                        left: `calc(${position}% - ${knobWidth / 2}px)`,
                        top: `${verticalOffset}px`,
                        cursor: "pointer",
                    }}
                    onMouseDown={handleMouseDown}
                ></div>
            </div>
        )
    }

    const hasMoreSteps = () => {
        const nextSteps = props.inputTypes.filter(
            (input) => input.step === `Step ${currentStep + 1}`
        )
        return nextSteps.length > 0
    }

    const getTotalSteps = () => {
        const steps = props.inputTypes.map((input) => input.step)
        const uniqueSteps = [...new Set(steps)] // Remove duplicates
        return uniqueSteps.length
    }

    const changeStep = (newStep) => {
        setCurrentStep(newStep)
        setStepHistory((prevHistory) => {
            // Check if jumping ahead or back, and adjust history accordingly
            if (!prevHistory.includes(newStep)) {
                // Handle jumping ahead
                const updatedHistory = prevHistory.concat(newStep) // or a more complex logic if needed

                return updatedHistory
            }
            // Handle normal or step back scenario
            return [...prevHistory].slice(0, prevHistory.indexOf(newStep) + 1)
        })
    }

    const goBackOneStep = () => {
        if (stepHistory.length > 1) {
            setStepHistory((prevHistory) => {
                const newHistory = prevHistory.slice(0, -1)
                const newStep = newHistory[newHistory.length - 1]

                // setCurrentStep should be called here to ensure it uses the updated history
                setCurrentStep(newStep)

                // Reset triggered conditions appropriately
                // Ensure this represents the actual logic you want for resetting conditions
                setTriggeredConditions({}) // Resetting all to initial state for example

                return newHistory // This is the updated history
            })
        } else {
        }
    }

    const shouldHideSubmitButton = () => {
        // Find the fields for the current step
        const fieldsForCurrentStep = props.inputTypes.filter(
            (field) => field.step === `Step ${currentStep}`
        )

        // Check if any field in the current step has hideSubmitButton set to true
        return fieldsForCurrentStep.some((field) => field.hideSubmitButton)
    }

    const StepIndicator = ({ step, isCurrent, isCompleted }) => {
        let backgroundColor
        if (isCurrent) backgroundColor = props.stepStyle.current
        // Or any color for the current step
        else if (isCompleted) backgroundColor = props.stepStyle.previous
        // Or any color for completed steps
        else backgroundColor = props.stepStyle.next // Or any color for future steps

        const handleClick = () => {
            if (!isCurrent && isCompleted) setCurrentStep(step)
        }

        return (
            <motion.div
                onClick={handleClick}
                style={{
                    height: "14px", // Or any size
                    width: "14px", // Or any size
                    borderRadius: "50%",
                    backgroundColor,
                    margin: "8px",
                    cursor: isCompleted ? "pointer" : "default",
                }}
                whileHover={step < currentStep ? { scale: 1.25 } : {}}
            ></motion.div>
        )
    }

    const countriesList = {
        Afghanistan: "🇦🇫 Afghanistan",
        Albania: "🇦🇱 Albania",
        Algeria: "🇩🇿 Algeria",
        "American Samoa": "🇦🇸 American Samoa",
        Andorra: "🇦🇩 Andorra",
        Angola: "🇦🇴 Angola",
        Anguilla: "🇦🇮 Anguilla",
        Antarctica: "🇦🇶 Antarctica",
        Argentina: "🇦🇷 Argentina",
        Armenia: "🇦🇲 Armenia",
        Aruba: "🇦🇼 Aruba",
        Australia: "🇦🇺 Australia",
        Austria: "🇦🇹 Austria",
        Azerbaijan: "🇦🇿 Azerbaijan",
        Bahamas: "🇧🇸 Bahamas",
        Bahrain: "🇧🇭 Bahrain",
        Bangladesh: "🇧🇩 Bangladesh",
        Barbados: "🇧🇧 Barbados",
        Belarus: "🇧🇾 Belarus",
        Belgium: "🇧🇪 Belgium",
        Belize: "🇧🇿 Belize",
        Benin: "🇧🇯 Benin",
        Bermuda: "🇧🇲 Bermuda",
        Bhutan: "🇧🇹 Bhutan",
        Bolivia: "🇧🇴 Bolivia",
        Bonaire: "🇧🇶 Bonaire",
        Botswana: "🇧🇼 Botswana",
        Brazil: "🇧🇷 Brazil",
        Bulgaria: "🇧🇬 Bulgaria",
        "Burkina Faso": "🇧🇫 Burkina Faso",
        Burundi: "🇧🇮 Burundi",
        "Cabo Verde": "🇨🇻 Cabo Verde",
        Cambodia: "🇰🇭 Cambodia",
        Cameroon: "🇨🇲 Cameroon",
        Canada: "🇨🇦 Canada",
        "Cayman Islands": "🇰🇾 Cayman Islands",
        Chad: "🇹🇩 Chad",
        Chile: "🇨🇱 Chile",
        China: "🇨🇳 China",
        Colombia: "🇨🇴 Colombia",
        Comoros: "🇰🇲 Comoros",
        Congo: "🇨🇬 Congo",
        "Costa Rica": "🇨🇷 Costa Rica",
        Croatia: "🇭🇷 Croatia",
        Cuba: "🇨🇺 Cuba",
        Curaçao: "🇨🇼 Curaçao",
        Cyprus: "🇨🇾 Cyprus",
        "Czech Republic": "🇨🇿 Czech Republic",
        "Côte d'Ivoire": "🇨🇮 Côte d'Ivoire",
        Denmark: "🇩🇰 Denmark",
        Djibouti: "🇩🇯 Djibouti",
        Dominica: "🇩🇲 Dominica",
        "Dominican Republic": "🇩🇴 Dominican Republic",
        Ecuador: "🇪🇨 Ecuador",
        Egypt: "🇪🇬 Egypt",
        "El Salvador": "🇸🇻 El Salvador",
        Eritrea: "🇪🇷 Eritrea",
        Estonia: "🇪🇪 Estonia",
        Eswatini: "🇸🇿 Eswatini",
        Ethiopia: "🇪🇹 Ethiopia",
        "Faroe Islands": "🇫🇴 Faroe Islands",
        Fiji: "🇫🇯 Fiji",
        Finland: "🇫🇮 Finland",
        France: "🇫🇷 France",
        "French Polynesia": "🇵🇫 French Polynesia",
        Gabon: "🇬🇦 Gabon",
        Gambia: "🇬🇲 Gambia",
        Georgia: "🇬🇪 Georgia",
        Germany: "🇩🇪 Germany",
        Ghana: "🇬🇭 Ghana",
        Gibraltar: "🇬🇮 Gibraltar",
        Greece: "🇬🇷 Greece",
        Greenland: "🇬🇱 Greenland",
        Grenada: "🇬🇩 Grenada",
        Guadeloupe: "🇬🇵 Guadeloupe",
        Guam: "🇬🇺 Guam",
        Guatemala: "🇬🇹 Guatemala",
        Guernsey: "🇬🇬 Guernsey",
        Guinea: "🇬🇳 Guinea",
        Guyana: "🇬🇾 Guyana",
        Haiti: "🇭🇹 Haiti",
        Honduras: "🇭🇳 Honduras",
        Hungary: "🇭🇺 Hungary",
        Iceland: "🇮🇸 Iceland",
        India: "🇮🇳 India",
        Indonesia: "🇮🇩 Indonesia",
        Iran: "🇮🇷 Iran",
        Iraq: "🇮🇶 Iraq",
        Ireland: "🇮🇪 Ireland",
        "Isle of Man": "🇮🇲 Isle of Man",
        Israel: "🇮🇱 Israel",
        Italy: "🇮🇹 Italy",
        Jamaica: "🇯🇲 Jamaica",
        Japan: "🇯🇵 Japan",
        Jersey: "🇯🇪 Jersey",
        Jordan: "🇯🇴 Jordan",
        Kazakhstan: "🇰🇿 Kazakhstan",
        Kenya: "🇰🇪 Kenya",
        Kiribati: "🇰🇮 Kiribati",
        Korea: "🇰🇷 Korea",
        Kuwait: "🇰🇼 Kuwait",
        Kyrgyzstan: "🇰🇬 Kyrgyzstan",
        Latvia: "🇱🇻 Latvia",
        Lebanon: "🇱🇧 Lebanon",
        Lesotho: "🇱🇸 Lesotho",
        Liberia: "🇱🇷 Liberia",
        Libya: "🇱🇾 Libya",
        Liechtenstein: "🇱🇮 Liechtenstein",
        Lithuania: "🇱🇹 Lithuania",
        Luxembourg: "🇱🇺 Luxembourg",
        Macao: "🇲🇴 Macao",
        Madagascar: "🇲🇬 Madagascar",
        Malawi: "🇲🇼 Malawi",
        Malaysia: "🇲🇾 Malaysia",
        Maldives: "🇲🇻 Maldives",
        Mali: "🇲🇱 Mali",
        Malta: "🇲🇹 Malta",
        "Marshall Islands": "🇲🇭 Marshall Islands",
        Martinique: "🇲🇶 Martinique",
        Mauritania: "🇲🇷 Mauritania",
        Mauritius: "🇲🇺 Mauritius",
        Mayotte: "🇾🇹 Mayotte",
        Mexico: "🇲🇽 Mexico",
        Moldova: "🇲🇩 Moldova",
        Monaco: "🇲🇨 Monaco",
        Mongolia: "🇲🇳 Mongolia",
        Montenegro: "🇲🇪 Montenegro",
        Montserrat: "🇲🇸 Montserrat",
        Morocco: "🇲🇦 Morocco",
        Mozambique: "🇲🇿 Mozambique",
        Myanmar: "🇲🇲 Myanmar",
        Namibia: "🇳🇦 Namibia",
        Nauru: "🇳🇷 Nauru",
        Nepal: "🇳🇵 Nepal",
        Netherlands: "🇳🇱 Netherlands",
        "New Caledonia": "🇳🇨 New Caledonia",
        "New Zealand": "🇳🇿 New Zealand",
        Nicaragua: "🇳🇮 Nicaragua",
        Niger: "🇳🇪 Niger",
        Nigeria: "🇳🇬 Nigeria",
        Niue: "🇳🇺 Niue",
        "Norfolk Island": "🇳🇫 Norfolk Island",
        "North Macedonia": "🇲🇰 North Macedonia",
        Norway: "🇳🇴 Norway",
        Oman: "🇴🇲 Oman",
        Pakistan: "🇵🇰 Pakistan",
        Palau: "🇵🇼 Palau",
        Palestine: "🇵🇸 Palestine",
        Panama: "🇵🇦 Panama",
        "Papua New Guinea": "🇵🇬 Papua New Guinea",
        Paraguay: "🇵🇾 Paraguay",
        Peru: "🇵🇪 Peru",
        Philippines: "🇵🇭 Philippines",
        Pitcairn: "🇵🇳 Pitcairn",
        Poland: "🇵🇱 Poland",
        Portugal: "🇵🇹 Portugal",
        "Puerto Rico": "🇵🇷 Puerto Rico",
        Qatar: "🇶🇦 Qatar",
        Romania: "🇷🇴 Romania",
        Russia: "🇷🇺 Russia",
        Rwanda: "🇷🇼 Rwanda",
        Réunion: "🇷🇪 Réunion",
        "Saint Lucia": "🇱🇨 Saint Lucia",
        Samoa: "🇼🇸 Samoa",
        "San Marino": "🇸🇲 San Marino",
        "Saudi Arabia": "🇸🇦 Saudi Arabia",
        Senegal: "🇸🇳 Senegal",
        Serbia: "🇷🇸 Serbia",
        Seychelles: "🇸🇨 Seychelles",
        "Sierra Leone": "🇸🇱 Sierra Leone",
        Singapore: "🇸🇬 Singapore",
        "Sint Maarten": "🇸🇽 Sint Maarten",
        Slovakia: "🇸🇰 Slovakia",
        Slovenia: "🇸🇮 Slovenia",
        "Solomon Islands": "🇸🇧 Solomon Islands",
        Somalia: "🇸🇴 Somalia",
        "South Africa": "🇿🇦 South Africa",
        "South Sudan": "🇸🇸 South Sudan",
        Spain: "🇪🇸 Spain",
        "Sri Lanka": "🇱🇰 Sri Lanka",
        Sudan: "🇸🇩 Sudan",
        Suriname: "🇸🇷 Suriname",
        Sweden: "🇸🇪 Sweden",
        Switzerland: "🇨🇭 Switzerland",
        Taiwan: "🇹🇼 Taiwan",
        Tajikistan: "🇹🇯 Tajikistan",
        Tanzania: "🇹🇿 Tanzania",
        Thailand: "🇹🇭 Thailand",
        Togo: "🇹🇬 Togo",
        Tonga: "🇹🇴 Tonga",
        "Trinidad and Tobago": "🇹🇹 Trinidad and Tobago",
        Tunisia: "🇹🇳 Tunisia",
        Turkey: "🇹🇷 Turkey",
        Turkmenistan: "🇹🇲 Turkmenistan",
        Tuvalu: "🇹🇻 Tuvalu",
        Uganda: "🇺🇬 Uganda",
        Ukraine: "🇺🇦 Ukraine",
        "United Arab Emirates": "🇦🇪 United Arab Emirates",
        "United Kingdom": "🇬🇧 United Kingdom",
        "United States of America": "🇺🇸 United States of America",
        Uruguay: "🇺🇾 Uruguay",
        Uzbekistan: "🇺🇿 Uzbekistan",
        Vanuatu: "🇻🇺 Vanuatu",
        Venezuela: "🇻🇪 Venezuela",
        "Viet Nam": "🇻🇳 Viet Nam",
        Yemen: "🇾🇪 Yemen",
        Zambia: "🇿🇲 Zambia",
        Zimbabwe: "🇿🇼 Zimbabwe",
    }

    function getCookieValue(name) {
        let matches = document.cookie.match(
            new RegExp(
                "(?:^|; )" +
                    name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
                    "=([^;]*)"
            )
        )
        return matches ? decodeURIComponent(matches[1]) : undefined
    }

    const RequiredFieldMarker = () => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 999,
                    width: 13,
                    height: 13,
                    backgroundColor:
                        props.inputStyle.styling.requiredStyles.backgroundColor,
                }}
            >
                <p
                    style={{
                        ...props.inputStyle.fontStyle.labelStyle,
                        fontSize: 20,
                        lineHeight: "13px",
                        verticalAlign: "center",
                        color: props.inputStyle.styling.requiredStyles.color,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingTop: 8,
                        paddingBottom: 0,
                    }}
                >
                    *
                </p>
            </div>
        )
    }

    const getUTMParams = () => {
        const queryParams = new URLSearchParams(window.location.search)
        const params = {
            utm_source: queryParams.get("utm_source"),
            utm_medium: queryParams.get("utm_medium"),
            utm_campaign: queryParams.get("utm_campaign"),
            utm_term: queryParams.get("utm_term"),
            utm_content: queryParams.get("utm_content"),
        }

        // Filter out null or undefined UTM parameters
        Object.keys(params).forEach((key) => {
            if (params[key] == null) {
                delete params[key]
            }
        })

        return params
    }

    const Slider = ({ currentStep, totalSteps }) => {
        const progress = (currentStep / totalSteps) * 100

        return (
            <div style={{ width: "100%" }}>
                <div
                    style={{
                        height: "10px",
                        width: "100%",
                        backgroundColor: props.stepStyle.next,
                        borderRadius: "5px",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            width: `${progress}%`,
                            backgroundColor: props.stepStyle.current,
                            borderRadius: "5px",
                            transition: "width 0.3s ease-in-out",
                        }}
                    />
                </div>
                {currentStep > 1 && (
                    <div
                        style={{
                            marginTop: 8,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <div
                            onMouseDown={(e) => {
                                e.preventDefault() // Stop any default behavior

                                goBackOneStep() // Call the function
                            }}
                            style={{
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "row",
                                gap: 4,
                                alignItems: "center",
                                zIndex: 10,
                            }}
                        >
                            <ArrowLeft
                                weight="bold"
                                style={{
                                    ...props.stepStyle.font,
                                    color: props.stepStyle.current,
                                }}
                            />
                            <p
                                style={{
                                    ...props.stepStyle.font,
                                    color: props.stepStyle.current,
                                }}
                            >
                                {props.stepStyle.text}
                            </p>
                        </div>
                    </div>
                )}{" "}
            </div>
        )
    }
    const validate = (step) => {
        let errors = {}
        const emailFieldConfig = props.inputTypes.find(
            (field) => field.type === "email"
        )
        const emailValidationType = emailFieldConfig
            ? emailFieldConfig.emailValidationType
            : "standard"

        props.inputTypes.forEach((field) => {
            // Only validate fields in the current step
            if (field.step === `Step ${step}` && field.required) {
                if (field.type === "file") {
                    console.log(
                        "Validating file field:",
                        field.name,
                        "Validity:",
                        fileValidity[field.name]
                    )
                    // Validate file input
                    const isFileValid = fileValidity[field.name] === true
                    if (!isFileValid) {
                        errors = {
                            ...errors,
                            [field.name]: "Please upload a file", // Customize this message as needed
                        }
                    }
                } else if (
                    !formValues[field.name] ||
                    formValues[field.name] === ""
                ) {
                    errors = {
                        ...errors,
                        [field.name]: field.validationMessage || "defaultError",
                    }
                } else if (field.type === "email" && formValues[field.name]) {
                    const isValidEmail = validateEmail(
                        formValues[field.name],
                        emailValidationType
                    )

                    if (!isValidEmail) {
                        errors = {
                            ...errors,
                            [field.name]: "Please insert your business e-mail",
                        }
                    }
                }
            }
        })

        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            ...errors,
        }))

        return Object.keys(errors).length === 0
    }

    const validateEmail = (email, validationType) => {
        const [localPart, domain] = email.split("@")
        const [domainName, topLevelDomain] = domain.split(".")

        const isCommonProvider = (domainName, topLevelDomain) => {
            const commonProviders = [
                "gmail",
                "hotmail",
                "yahoo",
                "outlook",
                "live",
                "msn",
                "aol",
                "ymail",
                "mail",
                "icloud",
                "me",
                "mac",
                "comcast",
                "sbcglobal",
                "verizon",
                "att",
                "bellsouth",
                "roadrunner",
                "cox",
                "earthlink",
                "charter",
                "aim",
                "zoho",
                "yandex",
                "protonmail",
                "gmx",
                "t-online",
                "web",
                "orange",
                "free",
                "wanadoo",
                "rediffmail",
                "mail.ru",
                "qq",
                "naver",
                "daum",
                "nate",
                "163",
                "126",
                "yeah",
                "sina",
                "sohu",
                "fastmail",
                "tutanota",
                "hushmail",
                "lavabit",
                "mailfence",
                "runbox",
                "posteo",
                "kolabnow",
                "mailbox",
                "gmxmail",
                "liberomail",
                "interia",
                "rambler",
                "inbox",
                "bluewin",
            ]

            return commonProviders.includes(domainName.toLowerCase())
        }

        if (validationType.toLowerCase() === "business") {
            return !isCommonProvider(domainName, topLevelDomain)
        } else {
            const emailRegex =
                /^[\w-]+(\+[\w-]+)?(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/
            return emailRegex.test(email)
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter" && event.target.tagName !== "TEXTAREA") {
            event.preventDefault() // Prevent the default action
            handleSubmit(event) // Call your submit handler
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setIsSubmitting(true)
        console.log("Form submission started")
        const utmParams = getUTMParams()
        const currentInputTypes = props.inputTypes.filter(
            (input) => input.step === `Step ${currentStep}`
        )

        let isFormValid = true
        let newValidationErrors = { ...validationErrors }

        // Check validations for all fields
        for (const field of currentInputTypes) {
            console.log("file chek")
            if (field.required) {
                const isValid =
                    field.type === "file"
                        ? fileValidity[field.name] === true
                        : formValues[field.name] !== undefined &&
                          formValues[field.name] !== ""

                if (!isValid) {
                    isFormValid = false
                    newValidationErrors[field.name] = field.validationMessage
                }
            }
        }
        console.log("set validation errrs")
        setValidationErrors(newValidationErrors)

        if (!isFormValid) {
            console.log("form is not valid")
            setValidationErrors(newValidationErrors)
            setIsSubmitting(false)
            return // Exit if the form is invalid
        }

        for (const field of currentInputTypes) {
            if (field.condition && !triggeredConditions[field.name]) {
                const { comparisonType, value, targetStep } = field.condition
                const fieldValue = formValues[field.name] // Value from the form for this field

                let conditionMet = false

                // Determine if the condition is met based on the comparisonType
                switch (comparisonType) {
                    case "equals":
                        conditionMet = fieldValue === value
                        break
                    case "equalsNot":
                        conditionMet = fieldValue !== value
                        break
                    case "higherThan":
                        conditionMet =
                            parseFloat(fieldValue) > parseFloat(value)
                        break
                    case "higherOrEqualThan":
                        conditionMet =
                            parseFloat(fieldValue) >= parseFloat(value)
                        break
                    case "lowerThan":
                        conditionMet =
                            parseFloat(fieldValue) < parseFloat(value)
                        break
                    case "lowerOrEqualThan":
                        conditionMet =
                            parseFloat(fieldValue) <= parseFloat(value)
                        break
                    case "contains":
                        conditionMet = fieldValue && fieldValue.includes(value)
                        break
                    case "doesNotContain":
                        conditionMet = fieldValue && !fieldValue.includes(value)
                        break
                    case "startsWith":
                        conditionMet =
                            fieldValue && fieldValue.startsWith(value)
                        break
                    case "endsWith":
                        conditionMet = fieldValue && fieldValue.endsWith(value)
                        break
                    case "isEmpty":
                        conditionMet = !fieldValue
                        break
                    case "isNotEmpty":
                        conditionMet = !!fieldValue
                        break
                    default:
                        conditionMet = false
                }

                if (conditionMet) {
                    console.log("next step")
                    const nextStep = parseInt(targetStep.replace("Step ", ""))
                    setCurrentStep(nextStep)
                    setTriggeredConditions((prev) => ({
                        ...prev,
                        [field.name]: true,
                    }))
                    setStepHistory((prevHistory) => {
                        if (!prevHistory.includes(nextStep)) {
                            return [...prevHistory, nextStep]
                        }
                        return prevHistory.slice(
                            0,
                            prevHistory.indexOf(nextStep) + 1
                        )
                    })
                    return
                }
            }
        }

        if (hasMoreSteps()) {
            const isCurrentStepValid = validate(currentStep)

            if (isCurrentStepValid) {
                changeStep(currentStep + 1)
                setValidationErrors({})
            } else {
                setIsSubmitting(false)
            }
        } else {
            const isFinalStepValid = validate(currentStep)

            if (isFinalStepValid) {
                setIsActuallySubmitting(true)
                let loadingInterval = setInterval(() => {
                    setLoadingDots((prevDots) =>
                        prevDots.length >= 3 ? "." : prevDots + "."
                    )
                }, 300)
                console.log("start with botpoisn")
                const { solution } = await botpoison.challenge()
                if (!solution) {
                    console.error("Failed to get Botpoison solution data")
                    clearInterval(loadingInterval)
                    setLoadingDots("")
                    setIsActuallySubmitting(false)
                    setIsSubmitting(false)
                    return
                }

                //   let submitUrl = `http://localhost:3001/api/submit/${props.formId}`
                let submitUrl = `https://magicform.onrender.com/api/submit/${props.formId}`
                try {
                    let formData = new FormData()
                    Object.keys(formValues).forEach((key) => {
                        const value = formValues[key]
                        if (
                            typeof value === "object" &&
                            value !== null &&
                            !(value instanceof File)
                        ) {
                            formData.append(key, JSON.stringify(value))
                        } else {
                            formData.append(key, value)
                        }
                    })

                    const url = window.location.href
                    formData.append("url", url)
                    formData.append("_botpoison", solution)

                    const gclAwValue = getCookieValue("_gcl_aw")
                    const gaValue = getCookieValue("_ga")
                    const fbpValue = getCookieValue("_fbp")
                    const hstcValue = getCookieValue("_hstc")

                    if (gclAwValue) formData.append("_gcl_aw", gclAwValue)
                    if (gaValue) formData.append("_ga", gaValue)
                    if (fbpValue) formData.append("_fbp", fbpValue)
                    if (hstcValue) formData.append("_hstc", hstcValue)

                    Object.keys(selectedFiles).forEach((fieldName) => {
                        selectedFiles[fieldName].forEach((file) => {
                            formData.append(fieldName, file, file.name)
                        })
                    })

                    Object.keys(utmParams).forEach((key) => {
                        if (utmParams[key]) {
                            formData.append(key, utmParams[key])
                        }
                    })

                    const response = await fetch(submitUrl, {
                        method: "POST",
                        body: formData,
                    })

                    if (response.ok) {
                        handleFormSuccess()
                    } else {
                        console.error(
                            `Form submission failed with status: ${response.status} ${response.statusText}`
                        )
                        try {
                            const responseBody = await response.text()
                            console.error(`Server response: ${responseBody}`)
                        } catch (readError) {
                            console.error(
                                `Error reading server response: ${readError}`
                            )
                        }
                    }
                } catch (error) {
                    console.error(`Error during form submission: ${error}`)
                } finally {
                    clearInterval(loadingInterval)
                    setLoadingDots("")
                    setIsActuallySubmitting(false)
                    setIsSubmitting(false)
                    console.log("Submission process ended, resetting states")
                }
            } else {
                setIsSubmitting(false)
            }
        }
    }

    const handleFormSuccess = () => {
        // If the request was successful, clear the form
        setFormValues({})
        setFormValues({})
        setSelectedFiles([])
        setFileValidity({ files: false })
        setStepHistory([1])
        setCurrentStep(1)

        if (props.success.afterSubmit === "redirect") {
            // Check if appending query params is enabled
            if (props.success.appendQueryParams) {
                // Serialize form values into a query string
                const queryParams = new URLSearchParams(formValues).toString()
                const redirectUrlWithParams = `${props.success.redirectURL}?${queryParams}`
                window.location.href = redirectUrlWithParams
            } else {
                // Redirect without appending query params
                window.location.href = props.success.redirectURL
            }
        } else {
            setToastVisible(true)
            if (props.success.timeout !== "infinite") {
                setTimeout(
                    () => setToastVisible(false),
                    parseInt(props.success.timeout, 10)
                )
            }
        }
    }

    const handleDragOver = (event) => {
        event.preventDefault() // Prevent default behavior
    }

    const fileInputRef = useRef(null)

    const removeFile = (index, fieldName) => {
        const updatedFiles = selectedFiles[fieldName].filter(
            (_, idx) => idx !== index
        )
        setSelectedFiles((prevFiles) => ({
            ...prevFiles,
            [fieldName]: updatedFiles,
        }))

        const isRequired = props.inputTypes.find(
            (field) => field.name === fieldName
        )?.required
        if (isRequired || updatedFiles.length > 0) {
            setFileValidity((prevValidity) => ({
                ...prevValidity,
                [fieldName]: updatedFiles.length > 0,
            }))
        }
    }

    const handleDrop = (event, fieldName) => {
        event.preventDefault()
        const files = Array.from(event.dataTransfer.files)
        processFiles(files, fieldName)
    }

    const processFiles = (files, fieldName) => {
        // Find the field configuration directly from the current field name
        const fieldConfig = props.inputTypes.find(
            (field) => field.name === fieldName
        )
        if (!fieldConfig) {
            console.error("Field configuration not found for", fieldName)
            return
        }

        const MAX_FILES =
            fieldConfig.maxFiles || props.inputStyle.fileStyle.maxFiles
        const MAX_FILE_SIZE = (fieldConfig.maxFileSize || 10) * 1024 * 1024

        const fileArray = Array.from(files)

        // Filter out files that are too large
        const validFiles = fileArray.filter((file) => {
            if (file.size > MAX_FILE_SIZE) {
                alert(
                    `File ${file.name} is too large. Maximum size is ${fieldConfig.maxFileSize}MB.`
                )
                return false // Exclude this file
            }
            return true // Include this file
        })

        // Limit the number of files
        const selectedFilesForField = validFiles.slice(0, MAX_FILES)

        setSelectedFiles((prevFiles) => ({
            ...prevFiles,
            [fieldName]: selectedFilesForField,
        }))
    }

    const clearFiles = () => {
        setSelectedFiles([])
        if (fileInputRef.current) {
            fileInputRef.current.value = ""
        }
    }

    const handleFileChange = (event, fieldName) => {
        const files = Array.from(event.target.files)
        processFiles(files, fieldName)

        setFileValidity((prevValidity) => ({
            ...prevValidity,
            [fieldName]: files.length > 0,
        }))

        // Clear validation error for this field when a file is added
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: undefined,
        }))
    }

    const handleFileMouseEnter = (fieldName) => {
        setIsFileHovered((prev) => ({ ...prev, [fieldName]: true }))
    }

    const handleFileMouseLeave = (fieldName) => {
        setIsFileHovered((prev) => ({ ...prev, [fieldName]: false }))
    }

    const handleRangeChange = (name, value) => {
        setFormValues({ ...formValues, [name]: value })
        setValidationErrors({ ...validationErrors, [name]: "" })
    }

    const handleChange = (
        event: React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value, type, checked } = event.target

        if (type === "checkbox") {
            const checkboxValues = formValues[name] || []

            if (checked) {
                checkboxValues.push(value)
            } else {
                const index = checkboxValues.indexOf(value)
                if (index > -1) {
                    checkboxValues.splice(index, 1)
                }
            }

            setFormValues({ ...formValues, [name]: checkboxValues })
        } else {
            setFormValues({ ...formValues, [name]: value })
        }

        if (validationErrors[name]) {
            setValidationErrors({ ...validationErrors, [name]: undefined })
        }
    }
    const renderLabelWithLinks = (label, link, matchWords) => {
        // Ensure matchWords is an array
        const wordsArray = Array.isArray(matchWords) ? matchWords : [matchWords]
        const regex = new RegExp(`(${wordsArray.join("|")})`, "gi")
        const parts = label.split(regex)

        return parts.map((part, index) =>
            regex.test(part) ? (
                <a
                    href={link}
                    key={index}
                    style={{
                        textDecoration: "underline",
                        color: props.inputStyle.checkboxStyle.linkColor,
                    }}
                >
                    {part}
                </a>
            ) : (
                part
            )
        )
    }

    const renderInputTypes = () => {
        const inputsForStep = props.inputTypes.filter(
            (input) => input.step === `Step ${currentStep}`
        )

        return inputsForStep.map((field, index) => {
            const error = validationErrors[field.name]
            const ErrorMessage = ({ field, validationErrors, errorColor }) => {
                if (
                    validationErrors[field.name] &&
                    validationErrors[field.name] !== "defaultError"
                ) {
                    return (
                        <div
                            style={{
                                paddingTop: "4px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "center",
                                color: errorColor,
                                gap: "8px",
                            }}
                        >
                            <Warning
                                size="18px"
                                weight="fill"
                                color={errorColor}
                            />
                            <p style={{ fontSize: "14px" }}>
                                {validationErrors[field.name]}
                            </p>
                        </div>
                    )
                }
                return null // Return null if there's no need to render anything
            }
            const defaultStyle = {
                appearance: "none",
                borderStyle: "solid",
                outline: "none",

                "::-moz-focus-inner": {
                    border: "0",
                },
                "::-moz-focus-outer": {
                    border: "0",
                },
            }

            const defaultInputStyle = {
                backgroundColor:
                    activeHoverField === field.name
                        ? props.inputStyle.styling.extraColorStyles.hoverColor
                        : props.inputStyle.styling.backgroundColor,

                borderWidth: props.inputStyle.styling.borderWidth.isMixed
                    ? `${props.inputStyle.styling.borderWidth.borderTopWidth}px ${props.inputStyle.styling.borderWidth.borderRightWidth}px ${props.inputStyle.styling.borderWidth.borderBottomWidth}px ${props.inputStyle.styling.borderWidth.borderLeftWidth}px`
                    : `${props.inputStyle.styling.borderWidth.defaultValue}px`,

                outline:
                    props.inputStyle.styling.extraColorStyles.useOutline ===
                    false
                        ? "0px"
                        : validationErrors[field.name] !== undefined &&
                            activeHoverField === field.name
                          ? `1.5px solid ${props.inputStyle.styling.extraColorStyles.errorColor}` // set error color if field is hovered and there are validation errors
                          : fieldActive[field.name]
                            ? `1.5px solid ${props.inputStyle.styling.extraColorStyles.activeColor}` // set active color if field is active
                            : activeHoverField === field.name
                              ? `1.5px solid ${props.inputStyle.styling.borderColor}` // set error color if
                              : "none", // if field is neither active nor hovered

                borderColor:
                    validationErrors[field.name] !== undefined
                        ? props.inputStyle.styling.extraColorStyles.errorColor
                        : fieldActive[field.name]
                          ? props.inputStyle.styling.extraColorStyles
                                .activeColor
                          : activeHoverField === field.name
                            ? props.inputStyle.styling.extraColorStyles
                                  .hoverBorderColor
                            : props.inputStyle.styling.borderColor,

                paddingLeft:
                    props.inputStyle.styling.iconPosition === "left" &&
                    field.toggleIcon
                        ? parseInt(props.inputStyle.styling.paddingLeft) +
                          parseInt(props.inputStyle.styling.iconSize) * 1.5
                        : props.inputStyle.styling.paddingLeft,
            }

            const iconPadding = {
                paddingLeft:
                    props.inputStyle.styling.iconPosition === "left" &&
                    field.toggleIcon
                        ? parseInt(props.inputStyle.styling.paddingLeft) +
                          parseInt(props.inputStyle.styling.iconSize) * 1.5
                        : props.inputStyle.styling.paddingLeft,
            }

            const radioStyle = {
                display: "flex",
                alignItems: "center",
                margin: "0px",
                borderRadius: props.inputStyle.checkboxStyle.borderRadius,
            }

            const iconStyle = {
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
            }
            const selectStyle = {
                appearance: "none",
                backgroundImage: "none",
                backgroundRepeat: "no-repeat",

                "&::-ms-expand": {
                    display: "none",
                },
            }

            const widthStyle =
                windowWidth <= 420
                    ? { gridColumn: "span 2" }
                    : field.width === "50% Width"
                      ? { gridColumn: "span 1" }
                      : { gridColumn: "span 2" }

            const formStyle = {
                display: "flex",
                flexDirection: "column",
            }

            const IconComponent = ({ iconName }) => {
                const SpecificIcon = iconsMap[iconName]
                return SpecificIcon ? (
                    <SpecificIcon
                        style={{
                            [props.inputStyle.styling.iconPosition]:
                                props.inputStyle.styling.iconPosition === "left"
                                    ? "12px"
                                    : props.inputStyle.styling.paddingRight,
                            ...props.inputStyle.fontStyle,
                            ...iconStyle,
                            color: props.inputStyle.styling.iconColor,
                            fontSize: props.inputStyle.styling.iconSize,
                        }}
                    />
                ) : null
            }

            const renderInput = (type, field, showIcon = false, renderIcon) => {
                const sharedStyles = {
                    ...props.inputStyle.styling,
                    ...props.inputStyle.fontStyle,
                    ...props.inputStyle.fontStyle.font,
                    ...defaultStyle,
                    paddingLeft: props.inputStyle.styling.paddingLeft,
                }

                const widthStyle =
                    windowWidth <= 420
                        ? { gridColumn: "span 2" }
                        : field.width === "50% Width"
                          ? { gridColumn: "span 1" }
                          : { gridColumn: "span 2" }

                const inputType =
                    type === "password" && showPassword[field.name]
                        ? "text"
                        : type

                const defaultIconComponent = showIcon ? (
                    <IconComponent iconName={field.icon} />
                ) : null

                const isMaxCharReached =
                    field.useMaxChar &&
                    formValues[field.name] &&
                    formValues[field.name].length >= field.maxChar

                return (
                    <div style={{ ...widthStyle, ...formStyle }}>
                        {field.showLabel && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent:
                                        props.inputStyle.styling.requiredStyles
                                            .justifyContent,
                                    gap: 8,
                                    alignItems: "center",
                                }}
                            >
                                <label
                                    style={{
                                        ...props.inputStyle.fontStyle.labelStyle
                                            .font,
                                        ...props.inputStyle.fontStyle
                                            .labelStyle,
                                    }}
                                    htmlFor={field.name}
                                >
                                    {field.label}
                                </label>
                                {field.required && <RequiredFieldMarker />}
                            </div>
                        )}
                        <div
                            style={{
                                ...formStyle,
                                position: "relative",
                            }}
                        >
                            <input
                                type={inputType}
                                className="magicform"
                                name={field.name}
                                value={
                                    formValues[field.name] !== undefined
                                        ? formValues[field.name]
                                        : field.defaultValue || ""
                                }
                                placeholder={`${field.placeholder}${
                                    field.showLabel
                                        ? ""
                                        : field.required
                                          ? " *"
                                          : ""
                                }`}
                                onChange={handleChange}
                                onMouseEnter={() =>
                                    setActiveHoverField(field.name)
                                }
                                onMouseLeave={() => setActiveHoverField(null)}
                                onFocus={() => {
                                    handleFieldActive(field.name, true)
                                }}
                                onBlur={() => {
                                    handleFieldActive(field.name, false)
                                }}
                                maxLength={
                                    field.useMaxChar ? field.maxChar : undefined
                                }
                                style={{
                                    ...sharedStyles,
                                    ...defaultInputStyle,
                                    ...iconPadding,
                                }}
                            />
                            {field.toggleIcon &&
                                (renderIcon
                                    ? renderIcon()
                                    : defaultIconComponent)}
                        </div>
                        {field.useMaxChar && (
                            <p
                                style={{
                                    ...props.inputStyle.fontStyle.labelStyle
                                        .font,
                                    ...props.inputStyle.fontStyle.labelStyle,
                                    fontSize: 12,
                                    fontWeight: 400,
                                    opacity: isMaxCharReached ? 1 : 0.5,
                                }}
                            >
                                {isMaxCharReached
                                    ? props.inputStyle.styling.extraColorStyles
                                          .messageMaxCharHit
                                    : `${
                                          formValues[field.name]
                                              ? formValues[field.name].length
                                              : 0
                                      }/${field.maxChar}`}
                            </p>
                        )}

                        <ErrorMessage
                            field={field}
                            validationErrors={validationErrors}
                            errorColor={
                                props.inputStyle.styling.extraColorStyles
                                    .errorColor
                            }
                        />
                    </div>
                )
            }

            const passwordIcon = () => {
                return showPassword[field.name] ? (
                    <EyeSlash
                        style={iconStyles}
                        onClick={() =>
                            setShowPassword({
                                ...showPassword,
                                [field.name]: !showPassword[field.name],
                            })
                        }
                    />
                ) : (
                    <Eye
                        style={iconStyles}
                        onClick={() =>
                            setShowPassword({
                                ...showPassword,
                                [field.name]: !showPassword[field.name],
                            })
                        }
                    />
                )
            }

            const iconStyles = {
                [props.inputStyle.styling.iconPosition]:
                    props.inputStyle.styling.iconPosition === "left"
                        ? "12px"
                        : props.inputStyle.styling.paddingRight,
                ...props.inputStyle.fontStyle,
                ...iconStyle,
                color: props.inputStyle.styling.iconColor,
                fontSize: props.inputStyle.styling.iconSize,
            }

            switch (field.type) {
                case "text":
                    return renderInput("text", field, true)
                case "email":
                    return renderInput("email", field, true)
                case "url":
                    return renderInput("url", field, true)

                case "password":
                    return renderInput("password", field, false, passwordIcon)
                // Add this case in your switch statement.
                case "file":
                    return (
                        <div
                            style={{
                                ...widthStyle,
                                ...formStyle,
                            }}
                        >
                            {field.showLabel && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent:
                                            props.inputStyle.styling
                                                .requiredStyles.justifyContent,
                                        gap: 8,
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                        }}
                                    >
                                        <label
                                            style={{
                                                ...props.inputStyle.fontStyle
                                                    .labelStyle.font,
                                                ...props.inputStyle.fontStyle
                                                    .labelStyle,
                                            }}
                                            htmlFor={field.name}
                                        >
                                            {field.label}
                                        </label>
                                        <p
                                            style={{
                                                ...props.inputStyle.fontStyle
                                                    .labelStyle.font,
                                                ...props.inputStyle.fontStyle
                                                    .labelStyle,
                                                fontSize: 8,
                                                paddingLeft: 4,
                                                opacity: 0.7,
                                            }}
                                        >
                                            ({field.maxFiles}){" "}
                                            {field.maxFiles > 1
                                                ? props.inputStyle.fileStyle
                                                      .file_plural
                                                : props.inputStyle.fileStyle
                                                      .file_single}
                                        </p>
                                    </div>
                                    {field.required && <RequiredFieldMarker />}
                                </div>
                            )}
                            <div
                                style={{
                                    position: "relative",
                                }}
                            >
                                <div
                                    onClick={() => {
                                        const currentRef =
                                            fileInputRefs.current[field.name]
                                        if (currentRef) {
                                            currentRef.click()
                                        }
                                    }}
                                    onDrop={(event) =>
                                        handleDrop(event, field.name)
                                    }
                                    onDragOver={handleDragOver}
                                    onMouseEnter={() =>
                                        handleFileMouseEnter(field.name)
                                    }
                                    onMouseLeave={() =>
                                        handleFileMouseLeave(field.name)
                                    }
                                    style={{
                                        border: "2px dashed",
                                        backgroundColor: isFileHovered[
                                            field.name
                                        ]
                                            ? props.inputStyle.styling
                                                  .extraColorStyles.hoverColor
                                            : props.inputStyle.styling
                                                  .backgroundColor,
                                        borderColor:
                                            validationErrors[field.name] !==
                                            undefined
                                                ? props.inputStyle.styling
                                                      .extraColorStyles
                                                      .errorColor
                                                : isFileHovered[field.name]
                                                  ? props.inputStyle.styling
                                                        .extraColorStyles
                                                        .hoverBorderColor
                                                  : props.inputStyle.styling
                                                        .borderColor,
                                        borderRadius:
                                            props.inputStyle.styling
                                                .borderRadius,
                                        ...props.inputStyle.fileStyle,
                                        textAlign: "center",
                                        display: "flex",
                                        flexDirection:
                                            field.inputSize === "large"
                                                ? "column"
                                                : "row",
                                        justifyContent:
                                            field.inputSize === "large"
                                                ? "center"
                                                : "flex-start",
                                        alignItems: "center",
                                        gap: 6,
                                    }}
                                >
                                    <div
                                        style={{
                                            marginRight:
                                                field.inputSize === "large"
                                                    ? 0
                                                    : 8,
                                            width: 32,
                                            height: 32,
                                            borderRadius:
                                                props.inputStyle.styling
                                                    .borderRadius,
                                            backgroundColor:
                                                props.inputStyle.fileStyle
                                                    .backgroundColor,
                                            border: "1px solid",
                                            borderColor:
                                                props.inputStyle.styling
                                                    .borderColor,
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <CloudArrowUp
                                            style={{
                                                ...props.inputStyle.fontStyle,
                                                ...props.inputStyle.fontStyle
                                                    .font,
                                                color: props.inputStyle
                                                    .fileStyle.iconColor,
                                                fontSize: 18,
                                            }}
                                        />
                                    </div>

                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <p
                                                style={{
                                                    ...props.inputStyle
                                                        .fontStyle,
                                                    ...props.inputStyle
                                                        .fontStyle.font,
                                                    fontWeight: 600,
                                                    lineHeight: "100%",
                                                }}
                                            >
                                                {
                                                    props.inputStyle.fileStyle
                                                        .boldText
                                                }
                                            </p>
                                            &nbsp;
                                            <p
                                                style={{
                                                    ...props.inputStyle
                                                        .fontStyle,
                                                    ...props.inputStyle
                                                        .fontStyle.font,
                                                    lineHeight: "100%",
                                                }}
                                            >
                                                {
                                                    props.inputStyle.fileStyle
                                                        .regularText
                                                }
                                            </p>
                                            {field.inputSize === "small" && (
                                                <span
                                                    style={{
                                                        ...props.inputStyle
                                                            .fontStyle,
                                                        ...props.inputStyle
                                                            .fontStyle.font,
                                                        opacity: 0.5,
                                                        fontSize: 8,
                                                        paddingLeft: 4,
                                                    }}
                                                >
                                                    max. {field.maxFileSize}
                                                    MB
                                                </span>
                                            )}
                                        </div>

                                        <p
                                            style={{
                                                display:
                                                    field.inputSize === "large"
                                                        ? "block"
                                                        : "none",
                                                ...props.inputStyle.fontStyle,
                                                ...props.inputStyle.fontStyle
                                                    .font,
                                                fontSize: 12,
                                                opacity: 0.5,
                                                paddingTop: 2,
                                            }}
                                        >
                                            max. {field.maxFileSize}MB
                                        </p>
                                    </div>

                                    <input
                                        type="file"
                                        accept="image/jpeg, image/png, application/pdf, text/plain, text/html, text/css, text/javascript, text/csv, application/xml, image/jpeg, image/png, image/gif, image/svg+xml, image/webp, audio/mpeg, audio/wav, audio/ogg, video/mp4, video/mpeg, video/x-msvideo, video/webm, video/ogg, video/quicktime, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.oasis.opendocument.text, application/vnd.oasis.opendocument.spreadsheet, application/json, application/zip, application/vnd.rar, application/x-7z-compressed, image/vnd.adobe.photoshop, application/vnd.microsoft.portable-executable, application/java-archive"
                                        multiple
                                        className="magicform"
                                        name={field.name}
                                        onChange={(event) =>
                                            handleFileChange(event, field.name)
                                        }
                                        ref={(element) => {
                                            if (element) {
                                                fileInputRefs.current[
                                                    field.name
                                                ] = element
                                            }
                                        }}
                                        style={{
                                            display: "none",
                                            gridColumn: "span 2",
                                            ...props.inputStyle.styling,
                                            ...props.inputStyle.fontStyle,
                                            ...props.inputStyle.fontStyle.font,
                                            ...defaultStyle,
                                            ...defaultInputStyle,
                                            paddingLeft:
                                                props.inputStyle.styling
                                                    .paddingLeft,
                                        }}
                                    />
                                </div>
                            </div>
                            {props.inputStyle.fileStyle.showSelectedFiles ===
                                true &&
                                selectedFiles[field.name] && (
                                    <div
                                        style={{
                                            paddingTop:
                                                props.containerStyle.gap,
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            gap: 4,
                                        }}
                                    >
                                        {selectedFiles[field.name].map(
                                            (file, index) => (
                                                <div
                                                    key={index}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        flexDirection: "row",
                                                        justifyContent:
                                                            "space-between",
                                                        width: "100%",
                                                        padding: "2px 0px",
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            ...props.inputStyle
                                                                .fontStyle,
                                                            ...props.inputStyle
                                                                .fontStyle.font,
                                                            flexGrow: 1,
                                                        }}
                                                    >
                                                        {file.name}
                                                    </p>

                                                    <X
                                                        onClick={() =>
                                                            removeFile(
                                                                index,
                                                                field.name
                                                            )
                                                        }
                                                        style={{
                                                            fontSize: "16px",
                                                            cursor: "pointer",
                                                            color: props
                                                                .inputStyle
                                                                .fontStyle
                                                                .color,
                                                        }}
                                                    />
                                                </div>
                                            )
                                        )}
                                    </div>
                                )}
                            <ErrorMessage
                                field={field}
                                validationErrors={validationErrors}
                                errorColor={
                                    props.inputStyle.styling.extraColorStyles
                                        .errorColor
                                }
                            />
                        </div>
                    )

                case "message":
                    // New case for message
                    return (
                        <div
                            style={{
                                ...widthStyle, // assuming you want to maintain the width style
                                ...formStyle, // and the form style from the range case
                            }}
                        >
                            {field.showLabel && (
                                <label
                                    style={{
                                        ...props.inputStyle.fontStyle.labelStyle
                                            .font,
                                        ...props.inputStyle.fontStyle
                                            .labelStyle,
                                    }}
                                    htmlFor={field.name}
                                >
                                    {field.label}
                                </label>
                            )}
                            <p
                                style={{
                                    ...props.inputStyle.fontStyle,
                                    ...props.inputStyle.fontStyle.font,
                                    userSelect: "none",
                                }}
                            >
                                {field.placeholder}
                            </p>
                        </div>
                    )

                case "range":
                    return (
                        <div
                            style={{
                                ...widthStyle,
                                ...formStyle,
                                marginTop:
                                    props.inputStyle.rangePicker.marginTop,
                                marginBottom:
                                    props.inputStyle.rangePicker.marginBottom,
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "flex-end",
                                }}
                            >
                                {field.showLabel && (
                                    <label
                                        style={{
                                            ...props.inputStyle.fontStyle
                                                .labelStyle.font,
                                            ...props.inputStyle.fontStyle
                                                .labelStyle,
                                        }}
                                        htmlFor={field.name}
                                    >
                                        {field.label}
                                    </label>
                                )}
                                <p
                                    style={{
                                        ...props.inputStyle.rangePicker.font,
                                        color: props.inputStyle.rangePicker
                                            .color,
                                        userSelect: "none",
                                    }}
                                >
                                    {formValues[field.name] || 0}{" "}
                                    {field.placeholder}
                                </p>
                            </div>

                            <RangePicker
                                name={field.name}
                                className="magicform"
                                value={formValues[field.name] || 0}
                                onChange={handleRangeChange}
                            />
                        </div>
                    )

                case "number":
                    return (
                        <div style={{ ...widthStyle, ...formStyle }}>
                            {field.showLabel && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent:
                                            props.inputStyle.styling
                                                .requiredStyles.justifyContent,
                                        gap: 8,
                                        alignItems: "center",
                                    }}
                                >
                                    <label
                                        style={{
                                            ...props.inputStyle.fontStyle
                                                .labelStyle.font,
                                            ...props.inputStyle.fontStyle
                                                .labelStyle,
                                        }}
                                        htmlFor={field.name}
                                    >
                                        {field.label}
                                    </label>
                                    {field.required && <RequiredFieldMarker />}
                                </div>
                            )}
                            <div
                                style={{
                                    ...widthStyle,
                                    ...formStyle,
                                    position: "relative",
                                }}
                            >
                                <input
                                    type="number"
                                    name={field.name}
                                    className="magicform"
                                    value={
                                        formValues[field.name] !== undefined
                                            ? formValues[field.name]
                                            : field.defaultValue || ""
                                    }
                                    placeholder={`${field.placeholder}${
                                        field.showLabel
                                            ? ""
                                            : field.required
                                              ? " *"
                                              : ""
                                    }`}
                                    onChange={handleChange}
                                    onMouseEnter={() =>
                                        setActiveHoverField(field.name)
                                    }
                                    onMouseLeave={() =>
                                        setActiveHoverField(null)
                                    }
                                    onFocus={() => {
                                        handleFieldActive(field.name, true)
                                    }}
                                    onBlur={() => {
                                        handleFieldActive(field.name, false)
                                    }}
                                    style={{
                                        ...props.inputStyle.styling,
                                        ...props.inputStyle.fontStyle,
                                        ...props.inputStyle.fontStyle.font,
                                        ...defaultInputStyle,
                                        ...defaultStyle,

                                        paddingLeft:
                                            props.inputStyle.styling
                                                .paddingLeft,
                                    }}
                                />
                            </div>
                            <ErrorMessage
                                field={field}
                                validationErrors={validationErrors}
                                errorColor={
                                    props.inputStyle.styling.extraColorStyles
                                        .errorColor
                                }
                            />
                        </div>
                    )

                case "date":
                    return (
                        <div
                            style={{
                                ...widthStyle,
                                ...formStyle,
                            }}
                        >
                            {field.showLabel && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent:
                                            props.inputStyle.styling
                                                .requiredStyles.justifyContent,
                                        gap: 8,
                                        alignItems: "center",
                                    }}
                                >
                                    <label
                                        style={{
                                            ...props.inputStyle.fontStyle
                                                .labelStyle.font,
                                            ...props.inputStyle.fontStyle
                                                .labelStyle,
                                        }}
                                        htmlFor={field.name}
                                    >
                                        {field.label}
                                    </label>
                                    {field.required && <RequiredFieldMarker />}
                                </div>
                            )}
                            <div
                                style={{
                                    ...widthStyle,
                                    ...formStyle,
                                    position: "relative",
                                }}
                            >
                                <input
                                    type="date"
                                    className="magicform"
                                    name={field.name}
                                    value={
                                        formValues[field.name] !== undefined
                                            ? formValues[field.name]
                                            : field.defaultValue || ""
                                    }
                                    placeholder={`${field.placeholder}${
                                        field.showLabel
                                            ? ""
                                            : field.required
                                              ? " *"
                                              : ""
                                    }`}
                                    onChange={handleChange}
                                    onMouseEnter={() =>
                                        setActiveHoverField(field.name)
                                    }
                                    onMouseLeave={() =>
                                        setActiveHoverField(null)
                                    }
                                    onFocus={() => {
                                        handleFieldActive(field.name, true)
                                    }}
                                    onBlur={() => {
                                        handleFieldActive(field.name, false)
                                    }}
                                    style={{
                                        ...props.inputStyle.styling,
                                        ...props.inputStyle.fontStyle,
                                        ...props.inputStyle.fontStyle.font,
                                        ...defaultInputStyle,
                                        ...defaultStyle,
                                        ...selectStyle,
                                        paddingLeft:
                                            props.inputStyle.styling
                                                .paddingLeft,
                                    }}
                                />
                            </div>
                            <ErrorMessage
                                field={field}
                                validationErrors={validationErrors}
                                errorColor={
                                    props.inputStyle.styling.extraColorStyles
                                        .errorColor
                                }
                            />
                        </div>
                    )

                case "time":
                    return (
                        <div style={{ ...widthStyle, ...formStyle }}>
                            {field.showLabel && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent:
                                            props.inputStyle.styling
                                                .requiredStyles.justifyContent,
                                        gap: 8,
                                        alignItems: "center",
                                    }}
                                >
                                    <label
                                        style={{
                                            ...props.inputStyle.fontStyle
                                                .labelStyle.font,
                                            ...props.inputStyle.fontStyle
                                                .labelStyle,
                                        }}
                                        htmlFor={field.name}
                                    >
                                        {field.label}
                                    </label>
                                    {field.required && <RequiredFieldMarker />}
                                </div>
                            )}
                            <div
                                style={{
                                    ...widthStyle,
                                    ...formStyle,
                                    position: "relative",
                                }}
                            >
                                <input
                                    type="time"
                                    className="magicform"
                                    name={field.name}
                                    value={
                                        formValues[field.name] !== undefined
                                            ? formValues[field.name]
                                            : field.defaultValue || ""
                                    }
                                    placeholder={`${field.placeholder}${
                                        field.showLabel
                                            ? ""
                                            : field.required
                                              ? " *"
                                              : ""
                                    }`}
                                    onChange={handleChange}
                                    onMouseEnter={() =>
                                        setActiveHoverField(field.name)
                                    }
                                    onMouseLeave={() =>
                                        setActiveHoverField(null)
                                    }
                                    onFocus={() => {
                                        handleFieldActive(field.name, true)
                                    }}
                                    onBlur={() => {
                                        handleFieldActive(field.name, false)
                                    }}
                                    style={{
                                        ...props.inputStyle.styling,
                                        ...props.inputStyle.fontStyle,
                                        ...props.inputStyle.fontStyle.font,
                                        ...defaultInputStyle,
                                        ...defaultStyle,
                                        ...selectStyle,
                                        paddingLeft:
                                            props.inputStyle.styling
                                                .paddingLeft,
                                    }}
                                />
                            </div>
                            <ErrorMessage
                                field={field}
                                validationErrors={validationErrors}
                                errorColor={
                                    props.inputStyle.styling.extraColorStyles
                                        .errorColor
                                }
                            />
                        </div>
                    )

                case "radio":
                    return (
                        <div
                            style={{
                                ...widthStyle,
                                ...formStyle,
                                gridColumn: "span 2",
                            }}
                        >
                            {field.showLabel && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent:
                                            props.inputStyle.styling
                                                .requiredStyles.justifyContent,
                                        gap: 8,
                                        alignItems: "center",
                                    }}
                                >
                                    <label
                                        style={{
                                            ...props.inputStyle.fontStyle
                                                .labelStyle.font,
                                            ...props.inputStyle.fontStyle
                                                .labelStyle,
                                        }}
                                        htmlFor={field.name}
                                    >
                                        {field.label}
                                    </label>
                                    {field.required && <RequiredFieldMarker />}
                                </div>
                            )}
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection:
                                        props.inputStyle.checkboxStyle
                                            .flexDirection,
                                    gap: props.inputStyle.checkboxStyle
                                        .marginBottom,
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                }}
                            >
                                {field.options.map((option, optionIndex) => {
                                    const uniqueId = `${field.name}-${option}` // Ensures unique ID for each radio option
                                    return (
                                        <label
                                            key={optionIndex}
                                            htmlFor={uniqueId}
                                            style={{
                                                display: "flex",
                                                flexGrow: 1,
                                                width:
                                                    props.inputStyle
                                                        .checkboxStyle
                                                        .flexDirection ===
                                                        "column" && "100%",
                                            }}
                                        >
                                            <div
                                                onMouseEnter={() =>
                                                    setActiveHoverField(
                                                        uniqueId
                                                    )
                                                }
                                                onMouseLeave={() =>
                                                    setActiveHoverField(null)
                                                }
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    flexGrow: 1,
                                                    backgroundColor:
                                                        formValues[
                                                            field.name
                                                        ] === option
                                                            ? props.inputStyle
                                                                  .checkboxStyle
                                                                  .selectedBGColor
                                                            : activeHoverField ===
                                                                uniqueId
                                                              ? props.inputStyle
                                                                    .styling
                                                                    .extraColorStyles
                                                                    .hoverColor
                                                              : props.inputStyle
                                                                    .checkboxStyle
                                                                    .backgroundColor,
                                                    ...(props.inputStyle
                                                        .checkboxStyle
                                                        .showActiveBorder && {
                                                        outline:
                                                            formValues[
                                                                field.name
                                                            ] === option
                                                                ? `1.5px solid ${props.inputStyle.styling.extraColorStyles.activeColor}`
                                                                : activeHoverField ===
                                                                    uniqueId
                                                                  ? `1.5px solid ${props.inputStyle.styling.extraColorStyles.hoverBorderColor}`
                                                                  : "none",
                                                    }),
                                                    ...radioStyle,
                                                    ...props.inputStyle
                                                        .checkboxStyle,
                                                    flexDirection: "row",
                                                }}
                                            >
                                                <input
                                                    type="radio"
                                                    id={uniqueId}
                                                    className="magicform"
                                                    name={field.name}
                                                    value={option}
                                                    checked={
                                                        formValues[
                                                            field.name
                                                        ] === option
                                                    }
                                                    onChange={handleChange}
                                                    style={{
                                                        flexShrink: 0,
                                                        width: props.inputStyle
                                                            .checkboxStyle.size,
                                                        height: props.inputStyle
                                                            .checkboxStyle.size,
                                                        marginTop: "0px",
                                                        accentColor:
                                                            props.inputStyle
                                                                .checkboxStyle
                                                                .accent,
                                                        colorScheme:
                                                            props.inputStyle
                                                                .checkboxStyle
                                                                .colorScheme,
                                                    }}
                                                />
                                                <span
                                                    style={{
                                                        paddingLeft: "8px",
                                                        ...props.inputStyle
                                                            .checkboxStyle.font,
                                                        color: props.inputStyle
                                                            .checkboxStyle
                                                            .color,
                                                    }}
                                                >
                                                    {option}
                                                </span>
                                            </div>
                                        </label>
                                    )
                                })}
                            </div>
                            <ErrorMessage
                                field={field}
                                validationErrors={validationErrors}
                                errorColor={
                                    props.inputStyle.styling.extraColorStyles
                                        .errorColor
                                }
                            />
                        </div>
                    )

                // Assuming this is within a switch statement in your rendering logic:

                case "spacer":
                    return (
                        <div
                            style={{
                                height: props.inputStyle.styling.spacerHeight, //
                                gridColumn: "span 2",
                                width: "100%", // Make the spacer take full width of its container
                            }}
                        />
                    )

                case "consent":
                    return (
                        <div
                            style={{
                                ...widthStyle,
                                ...formStyle,
                                gridColumn: "span 2",
                                marginTop: 12,
                                marginBottom: 12,
                            }}
                        >
                            <div
                                style={{
                                    display: "flex", // Added flex display here
                                    flexDirection:
                                        props.inputStyle.checkboxStyle
                                            .flexDirection,
                                    gap: props.inputStyle.checkboxStyle
                                        .marginBottom,
                                    flexWrap: "wrap", // Allows wrapping if too many items
                                    alignItems: "center", // Aligns items vertically in the center
                                }}
                            >
                                <label
                                    style={{
                                        display: "flex", // Ensuring flex display for internal alignment
                                        flexGrow: 1, // This will allow the div to grow
                                        width:
                                            props.inputStyle.checkboxStyle
                                                .flexDirection === "column" &&
                                            "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",

                                            alignItems: "center",
                                            flexGrow: 1,

                                            ...radioStyle,

                                            flexDirection: "row",
                                        }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="magicform"
                                            name={field.name}
                                            checked={!!formValues[field.name]}
                                            onChange={(event) => {
                                                // Directly set the form value for this checkbox to the checked state
                                                setFormValues((prevValues) => ({
                                                    ...prevValues,
                                                    [field.name]:
                                                        event.target.checked, // Use boolean directly
                                                }))
                                            }}
                                            style={{
                                                flexShrink: 0,
                                                width: props.inputStyle
                                                    .checkboxStyle.size,
                                                height: props.inputStyle
                                                    .checkboxStyle.size,
                                                marginTop: "0px",
                                                accentColor:
                                                    props.inputStyle
                                                        .checkboxStyle.accent,
                                                colorScheme:
                                                    props.inputStyle
                                                        .checkboxStyle
                                                        .colorScheme,
                                                width: 16,
                                                height: 16,
                                            }}
                                        />
                                        <span
                                            style={{
                                                paddingLeft: "8px",
                                                ...props.inputStyle
                                                    .checkboxStyle.font,
                                                color: props.inputStyle
                                                    .checkboxStyle.color,
                                                marginTop: -3,
                                            }}
                                        >
                                            {renderLabelWithLinks(
                                                field.label,
                                                field.link,
                                                field.matchWords
                                            )}
                                        </span>
                                    </div>
                                </label>
                            </div>
                            <ErrorMessage
                                field={field}
                                validationErrors={validationErrors}
                                errorColor={
                                    props.inputStyle.styling.extraColorStyles
                                        .errorColor
                                }
                            />
                        </div>
                    )

                case "select":
                case "country":
                    const fieldOptions =
                        field.type === "select"
                            ? field.selectOptions
                            : Object.entries(countriesList).map((keyValue) => {
                                  return {
                                      label: keyValue[1],
                                      value: keyValue[0],
                                  }
                              })
                    return (
                        <div
                            style={{
                                ...widthStyle,
                                ...formStyle,
                            }}
                        >
                            {field.showLabel && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent:
                                            props.inputStyle.styling
                                                .requiredStyles.justifyContent,
                                        gap: 8,
                                        alignItems: "center",
                                    }}
                                >
                                    <label
                                        style={{
                                            ...props.inputStyle.fontStyle
                                                .labelStyle.font,
                                            ...props.inputStyle.fontStyle
                                                .labelStyle,
                                        }}
                                        htmlFor={field.name}
                                    >
                                        {field.label}
                                    </label>
                                    {field.required && <RequiredFieldMarker />}
                                </div>
                            )}
                            <div
                                style={{
                                    ...widthStyle,
                                    ...formStyle,
                                    position: "relative",
                                }}
                            >
                                <select
                                    name={field.name}
                                    className="magicform"
                                    value={formValues[field.name] || ""}
                                    onChange={handleChange}
                                    onMouseEnter={() =>
                                        setActiveHoverField(field.name)
                                    }
                                    onMouseLeave={() =>
                                        setActiveHoverField(null)
                                    }
                                    onFocus={() => {
                                        handleFieldActive(field.name, true)
                                    }}
                                    onBlur={() => {
                                        handleFieldActive(field.name, false)
                                    }}
                                    style={{
                                        gridColumn: "span 2",
                                        ...props.inputStyle.styling,
                                        ...props.inputStyle.fontStyle,
                                        ...props.inputStyle.fontStyle.font,
                                        ...defaultStyle,
                                        ...defaultInputStyle,
                                        ...selectStyle,
                                        paddingLeft:
                                            props.inputStyle.styling
                                                .paddingLeft,
                                    }}
                                >
                                    <option value="">{`${field.placeholder}${
                                        field.showLabel
                                            ? ""
                                            : field.required
                                              ? " *"
                                              : ""
                                    }`}</option>
                                    {field.type === "country"
                                        ? fieldOptions.map(
                                              (option, optionIndex) => (
                                                  <option
                                                      key={optionIndex}
                                                      value={option.value}
                                                  >
                                                      {option.label}
                                                  </option>
                                              )
                                          )
                                        : field.options.map(
                                              (option, optionIndex) => (
                                                  <option
                                                      key={optionIndex}
                                                      value={option}
                                                  >
                                                      {option}
                                                  </option>
                                              )
                                          )}
                                </select>
                                <CaretDown
                                    style={{
                                        ...props.inputStyle.fontStyle,
                                        ...iconStyle,
                                        right: props.inputStyle.styling
                                            .paddingRight,
                                        color: props.inputStyle.styling
                                            .iconColor,
                                        fontSize: 13,
                                        weight: "bold",
                                    }}
                                />
                            </div>
                            <ErrorMessage
                                field={field}
                                validationErrors={validationErrors}
                                errorColor={
                                    props.inputStyle.styling.extraColorStyles
                                        .errorColor
                                }
                            />
                        </div>
                    )

                case "nps":
                    return (
                        <div
                            style={{
                                ...widthStyle,
                                ...formStyle,
                                gridColumn: "span 2",
                            }}
                        >
                            {field.showLabel && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent:
                                            props.inputStyle.styling
                                                .requiredStyles.justifyContent,
                                        gap: 8,
                                        alignItems: "center",
                                    }}
                                >
                                    <label
                                        style={{
                                            ...props.inputStyle.fontStyle
                                                .labelStyle.font,
                                            ...props.inputStyle.fontStyle
                                                .labelStyle,
                                        }}
                                        htmlFor={field.name}
                                    >
                                        {field.label}
                                    </label>
                                    {field.required && <RequiredFieldMarker />}
                                </div>
                            )}
                            <div
                                style={{
                                    marginTop: 4,
                                    display: "flex",
                                    justifyContent: "space-between",

                                    // ... Add additional styling here
                                }}
                            >
                                {Array.from({ length: 10 }, (_, index) => (
                                    <button
                                        type="button"
                                        key={index}
                                        onMouseEnter={() =>
                                            setHoveredIndex(index)
                                        }
                                        onMouseLeave={() =>
                                            setHoveredIndex(null)
                                        }
                                        onClick={() =>
                                            setFormValues({
                                                ...formValues,
                                                [field.name]: index + 1,
                                            })
                                        }
                                        style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            ...props.inputStyle.npsStyle,
                                            // Add button styling here
                                            // Use a different style if this value is selected
                                            backgroundColor:
                                                formValues[field.name] ===
                                                index + 1
                                                    ? props.inputStyle.npsStyle
                                                          .selectedBGColor
                                                    : props.inputStyle.npsStyle
                                                          .backgroundColor,
                                            borderColor:
                                                formValues[field.name] ===
                                                index + 1
                                                    ? props.inputStyle.npsStyle
                                                          .selectedBorderColor
                                                    : props.inputStyle.npsStyle
                                                          .borderColor,
                                            width: props.inputStyle.npsStyle
                                                .size,
                                            height: props.inputStyle.npsStyle
                                                .size,

                                            ...(props.inputStyle.npsStyle
                                                .showActiveBorder &&
                                                hoveredIndex === index && {
                                                    outline: `1.5px solid ${props.inputStyle.npsStyle.selectedBorderColor}`,
                                                }),

                                            ...(props.inputStyle.npsStyle
                                                .showActiveBorder &&
                                                formValues[field.name] ===
                                                    index + 1 && {
                                                    outline: `1.5px solid ${props.inputStyle.npsStyle.selectedBorderColor}`,
                                                }),
                                        }}
                                    >
                                        <p
                                            style={{
                                                fontSize:
                                                    props.inputStyle.npsStyle
                                                        .fontSize,
                                                color:
                                                    formValues[field.name] ===
                                                    index + 1
                                                        ? props.inputStyle
                                                              .npsStyle
                                                              .selectedColor
                                                        : props.inputStyle
                                                              .npsStyle.color,
                                                // ... Add additional styling for the button
                                            }}
                                        >
                                            {index + 1}
                                        </p>
                                    </button>
                                ))}
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginTop: "2px",
                                }}
                            >
                                <label
                                    style={{
                                        ...props.inputStyle.fontStyle.labelStyle
                                            .font,
                                        ...props.inputStyle.fontStyle
                                            .labelStyle,
                                        opacity: 0.5,
                                        fontSize:
                                            parseInt(
                                                props.inputStyle.fontStyle
                                                    .labelStyle.font
                                            ) - 4,
                                    }}
                                    htmlFor={field.name}
                                >
                                    {field.labelNpsLeft}
                                </label>
                                <label
                                    style={{
                                        ...props.inputStyle.fontStyle.labelStyle
                                            .font,
                                        ...props.inputStyle.fontStyle
                                            .labelStyle,
                                        opacity: 0.5,
                                        fontSize:
                                            parseInt(
                                                props.inputStyle.fontStyle
                                                    .labelStyle.font
                                            ) - 4,
                                    }}
                                    htmlFor={field.name}
                                >
                                    {field.labelNpsRight}
                                </label>
                            </div>
                            <ErrorMessage
                                field={field}
                                validationErrors={validationErrors}
                                errorColor={
                                    props.inputStyle.styling.extraColorStyles
                                        .errorColor
                                }
                            />
                        </div>
                    )

                case "textarea":
                    const isMaxCharReached =
                        field.useMaxChar &&
                        formValues[field.name] &&
                        formValues[field.name].length >= field.maxChar
                    return (
                        <div
                            style={{
                                ...widthStyle,
                                ...formStyle,
                            }}
                        >
                            {field.showLabel && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent:
                                            props.inputStyle.styling
                                                .requiredStyles.justifyContent,
                                        gap: 8,
                                        alignItems: "center",
                                    }}
                                >
                                    <label
                                        style={{
                                            ...props.inputStyle.fontStyle
                                                .labelStyle.font,
                                            ...props.inputStyle.fontStyle
                                                .labelStyle,
                                        }}
                                        htmlFor={field.name}
                                    >
                                        {field.label}
                                    </label>

                                    {field.required && <RequiredFieldMarker />}
                                </div>
                            )}
                            <textarea
                                name={field.name}
                                className="magicform"
                                placeholder={`${field.placeholder}${
                                    field.showLabel
                                        ? ""
                                        : field.required
                                          ? " *"
                                          : ""
                                }`}
                                value={
                                    formValues[field.name] !== undefined
                                        ? formValues[field.name]
                                        : field.defaultValue || ""
                                }
                                onChange={handleChange}
                                onMouseEnter={() =>
                                    setActiveHoverField(field.name)
                                }
                                onMouseLeave={() => setActiveHoverField(null)}
                                onFocus={() => {
                                    handleFieldActive(field.name, true)
                                }}
                                onBlur={() => {
                                    handleFieldActive(field.name, false)
                                }}
                                maxLength={
                                    field.useMaxChar ? field.maxChar : undefined
                                }
                                style={{
                                    resize: "none",
                                    gridColumn: "span 2",
                                    ...props.inputStyle.styling,
                                    ...props.inputStyle.fontStyle,
                                    ...props.inputStyle.fontStyle.font,
                                    ...defaultStyle,
                                    ...defaultInputStyle,
                                    paddingLeft:
                                        props.inputStyle.styling.paddingLeft,
                                }}
                                rows={field.rows} // Add this line
                            />

                            {field.useMaxChar && (
                                <p
                                    style={{
                                        ...props.inputStyle.fontStyle.labelStyle
                                            .font,
                                        ...props.inputStyle.fontStyle
                                            .labelStyle,
                                        fontSize: 12,
                                        fontWeight: 400,
                                        opacity: isMaxCharReached ? 1 : 0.5,
                                    }}
                                >
                                    {isMaxCharReached
                                        ? props.inputStyle.styling
                                              .extraColorStyles
                                              .messageMaxCharHit
                                        : `${
                                              formValues[field.name]
                                                  ? formValues[field.name]
                                                        .length
                                                  : 0
                                          }/${field.maxChar}`}
                                </p>
                            )}

                            <ErrorMessage
                                field={field}
                                validationErrors={validationErrors}
                                errorColor={
                                    props.inputStyle.styling.extraColorStyles
                                        .errorColor
                                }
                            />
                        </div>
                    )

                case "checkbox":
                    return (
                        <div
                            style={{
                                ...widthStyle,
                                ...formStyle,
                                gridColumn: "span 2",
                            }}
                        >
                            {field.showLabel && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent:
                                            props.inputStyle.styling
                                                .requiredStyles.justifyContent,
                                        gap: 8,
                                        alignItems: "center",
                                    }}
                                >
                                    <label
                                        style={{
                                            ...props.inputStyle.fontStyle
                                                .labelStyle.font,
                                            ...props.inputStyle.fontStyle
                                                .labelStyle,
                                        }}
                                        htmlFor={field.name}
                                    >
                                        {field.label}
                                    </label>
                                    {field.required && <RequiredFieldMarker />}
                                </div>
                            )}

                            {field.options.map((option, optionIndex) => {
                                const uniqueId = `${field.name}-${option}-${optionIndex}` // Ensures unique ID for each checkbox
                                return (
                                    <label key={optionIndex} htmlFor={uniqueId}>
                                        <div
                                            onMouseEnter={() =>
                                                setActiveHoverField(uniqueId)
                                            }
                                            onMouseLeave={() =>
                                                setActiveHoverField(null)
                                            }
                                            style={{
                                                backgroundColor: formValues[
                                                    field.name
                                                ]?.includes(option)
                                                    ? props.inputStyle
                                                          .checkboxStyle
                                                          .selectedBGColor
                                                    : activeHoverField ===
                                                        uniqueId
                                                      ? props.inputStyle.styling
                                                            .extraColorStyles
                                                            .hoverColor
                                                      : props.inputStyle
                                                            .checkboxStyle
                                                            .backgroundColor,
                                                ...(props.inputStyle
                                                    .checkboxStyle
                                                    .showActiveBorder && {
                                                    outline: formValues[
                                                        field.name
                                                    ]?.includes(option)
                                                        ? `1.5px solid ${props.inputStyle.styling.extraColorStyles.activeColor}`
                                                        : activeHoverField ===
                                                            uniqueId
                                                          ? `1.5px solid ${props.inputStyle.styling.extraColorStyles.hoverBorderColor}`
                                                          : "none",
                                                }),
                                                ...radioStyle,
                                                ...props.inputStyle
                                                    .checkboxStyle,
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                id={uniqueId}
                                                className="magicform"
                                                name={field.name}
                                                value={option}
                                                checked={formValues[
                                                    field.name
                                                ]?.includes(option)}
                                                onChange={handleChange}
                                                style={{
                                                    flexShrink: 0,
                                                    width: props.inputStyle
                                                        .checkboxStyle.size,
                                                    height: props.inputStyle
                                                        .checkboxStyle.size,
                                                    marginTop: "0px",
                                                    accentColor:
                                                        props.inputStyle
                                                            .checkboxStyle
                                                            .accent,
                                                    colorScheme:
                                                        props.inputStyle
                                                            .checkboxStyle
                                                            .colorScheme,
                                                }}
                                            />
                                            <span
                                                style={{
                                                    paddingLeft: "8px",
                                                    ...props.inputStyle
                                                        .checkboxStyle.font,
                                                    color: props.inputStyle
                                                        .checkboxStyle.color,
                                                }}
                                            >
                                                {option}
                                            </span>
                                        </div>
                                    </label>
                                )
                            })}
                            <ErrorMessage
                                field={field}
                                validationErrors={validationErrors}
                                errorColor={
                                    props.inputStyle.styling.extraColorStyles
                                        .errorColor
                                }
                            />
                        </div>
                    )

                default:
                    return null
            }
        })
    }

    return (
        <div
            className="magicform"
            dir={props.containerStyle.textDirection === "ltr" ? "ltr" : "rtl"}
            style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                userSelect: "none",
            }}
        >
            <form
                id={props.formId}
                onSubmit={handleSubmit}
                onKeyDown={handleKeyDown}
                // ... any other props you need ...

                style={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "space-between",
                    flexDirection: props.containerStyle.flexDirection,
                    display: toastVisible ? "none" : "flex",
                    pointerEvents: toastVisible ? "none" : "auto",
                    ...props.containerStyle,
                }}
                transition={{ duration: 0.3 }}
            >
                <div
                    style={{
                        ...props.containerStyle,
                        display: "grid",
                        maxWidth: "100%",
                        gridTemplateColumns: "1fr 1fr",
                        border: 0,
                        padding: 0,
                        margin: 0,
                        width: "-webkit-fill-available",
                    }}
                >
                    {renderInputTypes()}
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width:
                            props.containerStyle.flexDirection === "row"
                                ? "auto"
                                : "100%",
                        ...props.stepStyle,
                    }}
                >
                    {!shouldHideSubmitButton() && (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent:
                                        props.buttonStyle.buttonAlignment,
                                }}
                            >
                                {currentStep > 1 &&
                                    !props.stepStyle.showSteps && (
                                        <motion.button
                                            type="button"
                                            onClick={goBackOneStep}
                                            style={{
                                                cursor: "pointer",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginRight: "4px",
                                                appearance: "none",
                                                borderStyle: "solid",
                                                outline: "none",
                                                boxShadow: "none",
                                                "::-moz-focus-inner": {
                                                    border: "0",
                                                },
                                                "::-moz-focus-outer": {
                                                    border: "0",
                                                },
                                                ...props.buttonStyle.fontStyle
                                                    .font,
                                                ...props.buttonStyle.fontStyle,
                                                ...props.buttonStyle.styling,
                                            }}
                                            whileTap={{ scale: 0.99 }}
                                            whileHover={
                                                {
                                                    // ... hover styling here
                                                }
                                            }
                                        >
                                            <ArrowLeft
                                                style={{
                                                    ...props.buttonStyle
                                                        .fontStyle.font,
                                                    ...props.buttonStyle
                                                        .fontStyle,
                                                }}
                                            />

                                            {` ${props.stepStyle.text}`}
                                        </motion.button>
                                    )}
                                <motion.button
                                    type="submit"
                                    disabled={isActuallySubmitting}
                                    style={{
                                        cursor: "pointer",
                                        display: "inline-flex", // Use inline-flex to encourage horizontal layout
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "8px", // Ensure gap for spacing between icon and text
                                        appearance: "none",
                                        borderStyle: "solid",
                                        outline: "none",
                                        boxShadow: "none",
                                        minWidth: props.buttonStyle.width, // Use minWidth to allow growth
                                        maxWidth: "100%", // Prevent overflow
                                        whiteSpace: "nowrap", // Prevent line breaking
                                        overflow: "hidden", // Hide overflow
                                        textOverflow: "ellipsis", // Ellipsis for overly long text
                                        // Remove fixed width and height to allow dynamic sizing
                                        "::-moz-focus-inner": {
                                            border: "0",
                                        },
                                        "::-moz-focus-outer": {
                                            border: "0",
                                        },
                                        ...props.buttonStyle.fontStyle.font,
                                        ...props.buttonStyle.fontStyle,
                                        ...props.buttonStyle.styling,
                                    }}
                                    whileTap={{ scale: 0.99 }}
                                    whileHover={{
                                        backgroundColor:
                                            props.buttonStyle.styling
                                                .hoverColor,
                                    }}
                                >
                                    {isActuallySubmitting ? (
                                        <>
                                            {props.buttonStyle.loaderSubmit}{" "}
                                            {props.buttonStyle.showLoadingDots
                                                ? loadingDots
                                                : null}
                                        </>
                                    ) : (
                                        <>
                                            {props.buttonStyle.styling
                                                .showIcon && <PaperPlaneTilt />}
                                            {hasMoreSteps()
                                                ? props.buttonStyle.buttonNext
                                                : props.buttonStyle
                                                      .buttonSubmit}
                                        </>
                                    )}
                                </motion.button>
                            </div>

                            {getTotalSteps() > 1 &&
                                props.stepStyle.showSteps === true && (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {props.stepStyle.stepIndicatorType ===
                                        "slider" ? (
                                            <Slider
                                                currentStep={currentStep}
                                                totalSteps={getTotalSteps()}
                                            />
                                        ) : (
                                            Array.from(
                                                { length: getTotalSteps() },
                                                (_, i) => (
                                                    <StepIndicator
                                                        key={i + 1}
                                                        step={i + 1}
                                                        isCurrent={
                                                            currentStep ===
                                                            i + 1
                                                        }
                                                        isCompleted={
                                                            currentStep > i + 1
                                                        }
                                                    />
                                                )
                                            )
                                        )}
                                    </div>
                                )}
                        </>
                    )}
                </div>
            </form>

            {toastVisible && (
                <motion.div
                    initial={{ opacity: 0, y: 100 }}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginTop: "40px",
                        width: props.buttonStyle.width,
                    }}
                    animate={{
                        opacity: 1,
                        y: 0,
                    }}
                    transition={{ duration: 0.3 }}
                >
                    <CheckCircle
                        size={props.success.toastIcon}
                        weight="fill"
                        color={props.success.iconColor}
                    />
                    <div style={{ textAlign: "center", marginTop: "12px" }}>
                        <p
                            style={{
                                ...props.success.toastFontTitle.font,
                                ...props.success.toastFontTitle,
                                paddingBottom: "4px",
                            }}
                        >
                            {props.success.messageTitle}
                        </p>
                        <p
                            style={{
                                ...props.success.toastFontText.font,
                                ...props.success.toastFontText,
                            }}
                        >
                            {props.success.messageText}
                        </p>
                    </div>
                </motion.div>
            )}
        </div>
    )
}
const iconNames = Object.keys(iconsMap)

addPropertyControls(MagicFormPro, {
    formId: {
        type: ControlType.String,
        title: "FormID",
        defaultValue: "",
        description: "Enter the Form ID from MagicForm.",
    },

    // Inside addPropertyControls(MagicFormPro, { ... })

    inputTypes: {
        type: ControlType.Array,

        propertyControl: {
            type: ControlType.Object,
            controls: {
                type: {
                    type: ControlType.Enum,
                    title: "Input Type",
                    options: [
                        "text",
                        "email",
                        "file",
                        "range",
                        "textarea",
                        "country",
                        "number",
                        "url",
                        "date",
                        "time",
                        "nps",
                        "password",
                        "select",
                        "radio",
                        "checkbox",
                        "spacer",
                        "message",
                        "consent",
                    ],
                    optionTitles: [
                        "Text input",
                        "Email input",
                        "File upload",
                        "Range input",
                        "TextArea input",
                        "Country input",
                        "Number input",
                        "URL input",
                        "Date input",
                        "Time input",
                        "NPS score",
                        "Password input",
                        "Dropdown",
                        "Radio input",
                        "Checkbox",
                        "spacer",
                        "Message",
                        "Ask consent",
                    ],
                },

                inputSize: {
                    type: ControlType.Enum,
                    title: "Input size",
                    options: ["large", "small"],
                    optionTitles: ["Large", "Small"],
                    defaultValue: "large",
                    hidden: (props) => props.type !== "file",
                },

                maxFileSize: {
                    type: ControlType.Number,
                    title: "File size",
                    defaultValue: 10,
                    min: 5,
                    max: 50,
                    step: 5,
                    hidden: (props) => props.type !== "file",
                },

                maxFiles: {
                    type: ControlType.Number,
                    title: "Files amount",
                    defaultValue: 1,
                    min: 1,
                    max: 5,
                    step: 1,
                    hidden: (props) => props.type !== "file",
                },

                step: {
                    type: ControlType.Enum,
                    title: "Step",
                    options: [
                        "Step 1",
                        "Step 2",
                        "Step 3",
                        "Step 4",
                        "Step 5",
                        "Step 6",
                        "Step 7",
                        "Step 8",
                        "Step 9",
                        "Step 10",
                        "Step 11",
                        "Step 12",
                        "Step 13",
                        "Step 14",
                        "Step 15",
                        "Step 16",
                        "Step 17",
                        "Step 18",
                        "Step 19",
                        "Step 20",
                        "Step 21",
                        "Step 22",
                        "Step 23",
                        "Step 24",
                        "Step 25",
                        "Step 26",
                        "Step 27",
                        "Step 28",
                        "Step 29",
                        "Step 30",
                    ],
                    defaultValue: "Step 1",
                },

                condition: {
                    // new conditional logic control
                    type: ControlType.Object,
                    title: "Conditional",
                    controls: {
                        comparisonType: {
                            type: ControlType.Enum,
                            title: "Comparison",
                            options: [
                                "equals",
                                "equalsNot",
                                "higherThan",
                                "higherOrEqualThan",
                                "lowerThan",
                                "lowerOrEqualThan",
                                "contains",
                                "doesNotContain",
                                "startsWith",
                                "endsWith",
                                "isEmpty",
                                "isNotEmpty",
                                // ... add more as needed
                            ],
                            optionTitles: [
                                "Is",
                                "Is not",
                                "Is higher than",
                                "Is higher or equal than",
                                "Is lower than",
                                "Is lower or equal than",
                                "Contains",
                                "Does not contains",
                                "Starts with",
                                "Ends with",
                                "Is empty",
                                "Is not empty",
                                // ... add more as needed
                            ],
                        },
                        value: {
                            type: ControlType.String,
                            title: "Value",
                        },
                        targetStep: {
                            type: ControlType.Enum,
                            title: "Jump to step",
                            options: [
                                "Step 1",
                                "Step 2",
                                "Step 3",
                                "Step 4",
                                "Step 5",
                                "Step 6",
                                "Step 7",
                                "Step 8",
                                "Step 9",
                                "Step 10",
                                "Step 11",
                                "Step 12",
                                "Step 13",
                                "Step 14",
                                "Step 15",
                                "Step 16",
                                "Step 17",
                                "Step 18",
                                "Step 19",
                                "Step 20",
                                "Step 21",
                                "Step 22",
                                "Step 23",
                                "Step 24",
                                "Step 25",
                                "Step 26",
                                "Step 27",
                                "Step 28",
                                "Step 29",
                                "Step 30",
                            ],
                            description:
                                "Enter the step you want to jump towards",
                        },
                    },
                    hidden: (props) =>
                        ["consent", "spacer", "message"].includes(props.type),
                },

                name: {
                    type: ControlType.String,
                    title: "Value",
                    placeholder: "fieldName",
                    description:
                        "The value you enter here will be used in the response.",
                    hidden: (props) =>
                        ["spacer", "message"].includes(props.type),
                },

                showLabel: {
                    type: ControlType.Boolean,
                    title: "Display Label",
                    defaultValue: true,
                },
                label: {
                    type: ControlType.String,
                    title: "Label",
                    defaultValue: "Label",
                    hidden(props) {
                        return !props.showLabel
                    },
                },
                labelNpsLeft: {
                    type: ControlType.String,
                    title: "Left label",
                    defaultValue: "Very unlikely",
                    hidden: (props) => props.type !== "nps",
                },
                labelNpsRight: {
                    type: ControlType.String,
                    title: "Right label",
                    defaultValue: "Extremely likely",
                    hidden: (props) => props.type !== "nps",
                },

                placeholder: {
                    type: ControlType.String,
                    title: "Placeholder",
                    defaultValue: "Enter Text",
                    hidden: (props) =>
                        [
                            "radio",
                            "date",
                            "nps",
                            "spacer",
                            "consent",
                            "time",
                            "file",
                            "checkbox",
                        ].includes(props.type),
                },
                required: {
                    type: ControlType.Boolean,
                    title: "Required",
                    defaultValue: false,
                    hidden: (props) =>
                        ["range", "message", "spacer"].includes(props.type),
                },

                emailValidationType: {
                    type: ControlType.Enum,
                    title: "Email Validation Type",
                    options: ["standard", "business"],
                    optionTitles: ["Standard", "Business"],
                    defaultValue: "Standard",
                    hidden: (props) =>
                        props.type !== "email" || !props.required,
                },

                validationMessage: {
                    title: "Validation Message",
                    type: ControlType.String,
                    defaultValue: "", // default value
                    hidden: (props) => props.required === false,
                    description:
                        "Leave this field empty to only show error on the border",
                },

                useMaxChar: {
                    type: ControlType.Boolean,
                    title: "Add Max Characters",
                    defaultValue: false,
                    hidden: (props) =>
                        [
                            "radio",
                            "date",
                            "nps",
                            "spacer",
                            "consent",
                            "time",
                            "file",
                            "range",
                            "message",
                            "select",
                            "country",
                            "checkbox",
                        ].includes(props.type),
                },

                maxChar: {
                    type: ControlType.Number,
                    title: "Max Characters",
                    defaultValue: 25,
                    min: 1,
                    max: 1000,
                    step: 5,
                    hidden(props) {
                        return !props.useMaxChar
                    },
                },

                link: {
                    title: "Privacy Policy URL",
                    type: ControlType.Link,
                    hidden: (props) => props.type !== "consent",
                },

                matchWords: {
                    title: "Show link on words",
                    type: ControlType.String,
                    defaultValue: "Privacy policy", // default value
                    description: "Type the words that should contain the link",
                    hidden: (props) => props.type !== "consent",
                },

                defaultValue: {
                    type: ControlType.String,
                    title: "Default value",
                    hidden: (props) =>
                        [
                            "country",
                            "range",
                            "radio",
                            "date",
                            "nps",
                            "spacer",
                            "consent",
                            "message",
                            "file",
                            "checkbox",
                        ].includes(props.type),
                },

                options: {
                    type: ControlType.Array,
                    title: "Options",
                    defaultValue: ["Option 1", "Option 2"],
                    hidden: (props) =>
                        props.type !== "radio" &&
                        props.type !== "select" &&
                        props.type !== "checkbox",
                    propertyControl: {
                        type: ControlType.String,
                    },
                },

                rows: {
                    type: ControlType.Number,
                    title: "Rows",
                    defaultValue: 3,
                    hidden: (props) => props.type !== "textarea",
                },

                toggleIcon: {
                    type: ControlType.Boolean,
                    title: "Show icon",
                    defaultValue: true,
                    hidden: (props) =>
                        [
                            "number",
                            "select",
                            "range",
                            "textarea",
                            "nps",
                            "radio",
                            "date",
                            "time",
                            "spacer",
                            "consent",
                            "checkbox",
                            "file",
                            "message",
                        ].includes(props.type),
                },

                icon: {
                    type: ControlType.Enum,
                    title: "Select icon",
                    options: iconNames, // updated to use the new iconNames array
                    defaultValue: iconNames[0], // ensure this is a sensible default or perhaps the most commonly used icon
                    hidden: (props) =>
                        [
                            "password",
                            "select",
                            "range",
                            "number",
                            "date",
                            "spacer",
                            "time",
                            "nps",
                            "textarea",
                            "consent",
                            "radio",
                            "checkbox",
                            "file",
                            "message",
                        ].includes(props.type) || !props.toggleIcon,
                },
                width: {
                    type: ControlType.Enum,
                    title: "Width",
                    options: ["Full Width", "50% Width"],
                    optionTitles: ["Full Width", "1/2 Width"],
                    defaultValue: "Full Width",
                    displaySegmentedControl: true,
                    segmentedControlDirection: "horizontal",
                    hidden: (props) =>
                        [
                            "radio",
                            "select",
                            "country",
                            "checkbox",
                            "nps",
                            "range",
                            "spacer",
                            "range",
                            "textarea",
                            "consent",
                            "file",
                            "message",
                        ].includes(props.type),
                    propertyControl: {
                        type: ControlType.String,
                    },
                },
                hideSubmitButton: {
                    type: ControlType.Boolean,
                    title: "Hide Submit Button",
                    defaultValue: false, // By default, the submit button is visible
                    hidden: (props) => props.type !== "message",
                    description: "This hides the submit button from view",
                },
            },
        },
    },

    containerStyle: {
        type: ControlType.Object,
        title: "Container",
        controls: {
            gap: {
                type: ControlType.Number,
                title: "Gap",
                defaultValue: 8,
            },
            flexDirection: {
                type: ControlType.Enum,
                title: "Flex direction",
                options: ["row", "column"],
                optionTitles: ["Row", "Column"],
                defaultValue: "column",
            },
            textDirection: {
                type: ControlType.Enum,
                title: "Text direction",
                options: ["ltr", "rtl"],
                optionTitles: ["Left to Right", "Right to Left"],
                defaultValue: "ltr",
            },
        },
    },

    stepStyle: {
        type: ControlType.Object,
        title: "Steps",
        controls: {
            showSteps: {
                type: ControlType.Boolean,
                title: "Show Steps",
                defaultValue: false, // By default, the submit button is visible
                description: "This shows/hides the steps from the users view",
            },
            stepIndicatorType: {
                type: ControlType.Enum,
                title: "Use",
                options: ["slider", "circle"],
                optionTitles: ["Slider", "Circle"],
                defaultValue: "slider",
                hidden(props) {
                    return !props.showSteps
                },
            },

            text: {
                type: ControlType.String,
                title: "Go back text",
                defaultValue: "Back",
                hidden: (props) => props.stepIndicatorType === "circle",
            },
            gap: {
                type: ControlType.Number,
                title: "Gap",
                defaultValue: 16,
            },
            current: {
                type: ControlType.Color,
                title: "Current step",
                defaultValue: "#2A00F7",
            },
            previous: {
                type: ControlType.Color,
                title: "Previous step",
                defaultValue: "#2A00F7",
                hidden: (props) => props.stepIndicatorType === "slider",
            },
            next: {
                type: ControlType.Color,
                title: "Next step",
                defaultValue: "#eeeeee",
            },

            font: {
                type: ControlType.Font,
                controls: "extended",
                hidden: (props) => props.stepIndicatorType === "circle",
            },
        },
    },

    inputStyle: {
        type: ControlType.Object,
        title: "Styling",
        controls: {
            styling: {
                type: ControlType.Object,
                title: "Inputs",
                controls: {
                    backgroundColor: {
                        type: ControlType.Color,
                        title: "Background Color",
                        defaultValue: "#fafafa",
                    },

                    borderColor: {
                        type: ControlType.Color,
                        title: "Border Color",
                        defaultValue: "#dddddd",
                    },

                    borderWidth: {
                        type: ControlType.FusedNumber,
                        title: "Border width",
                        defaultValue: 1,
                        toggleKey: "isMixed",
                        toggleTitles: ["All", "Individual"],
                        valueKeys: [
                            "borderTopWidth",
                            "borderRightWidth",
                            "borderBottomWidth",
                            "borderLeftWidth",
                        ],
                        valueLabels: ["T", "R", "B", "L"],
                        min: 0,
                    },

                    borderRadius: {
                        type: ControlType.Number,
                        title: "Border Radius",
                        defaultValue: 10,
                    },

                    padding: {
                        type: ControlType.FusedNumber,
                        title: "Padding",
                        defaultValue: 0,
                        toggleKey: "isMixed",
                        toggleTitles: ["All", "Individual"],
                        valueKeys: [
                            "paddingTop",
                            "paddingRight",
                            "paddingBottom",
                            "paddingLeft",
                        ],
                        valueLabels: ["T", "R", "B", "L"],
                        min: 0,
                    },

                    iconPosition: {
                        type: ControlType.Enum,
                        title: "Position",
                        options: ["left", "right"],
                        optionTitles: ["Left", "Right"],
                        defaultValue: "right",
                    },
                    iconSize: {
                        type: ControlType.Number,
                        title: "Icon size",
                        defaultValue: 24,
                        min: 0,
                        step: 1,
                    },
                    iconColor: {
                        type: ControlType.Color,
                        title: "Icon Color",
                        defaultValue: "#000000",
                    },

                    spacerHeight: {
                        type: ControlType.Number,
                        title: "Spacer height",
                        defaultValue: 12,
                        min: 0,
                        step: 1,
                    },

                    extraColorStyles: {
                        type: ControlType.Object,
                        title: "Active / Error",
                        controls: {
                            hoverColor: {
                                type: ControlType.Color,
                                title: "Hover bg Color",
                                defaultValue: "#ffffff",
                            },

                            hoverBorderColor: {
                                type: ControlType.Color,
                                title: "Hover border",
                                defaultValue: "#2A00F725",
                            },

                            activeColor: {
                                type: ControlType.Color,
                                title: "Active Color",
                                defaultValue: "#2A00F7",
                            },

                            errorColor: {
                                type: ControlType.Color,
                                title: "Error Color",
                                defaultValue: "#ff0020",
                            },

                            useOutline: {
                                type: ControlType.Boolean,
                                title: "Use outline",
                                defaultValue: true,
                                description:
                                    "This will place a larger outline around the input when selected/hovered/error",
                            },
                            messageMaxCharHit: {
                                type: ControlType.String,
                                title: "Max. character text",
                                defaultValue: "You've used too many characters",
                            },
                        },
                    },
                    requiredStyles: {
                        type: ControlType.Object,
                        title: "Required Style",
                        controls: {
                            backgroundColor: {
                                type: ControlType.Color,
                                title: "Background Color",
                                defaultValue: "#dddddd",
                            },

                            color: {
                                type: ControlType.Color,
                                title: "Asterisk color",
                                defaultValue: "#000",
                            },
                            justifyContent: {
                                type: ControlType.Enum,
                                title: "Justify Content",
                                options: ["space-between", "flex-start"],
                                optionTitles: ["space-between", "flex-start"],
                                defaultValue: "space-between",
                            },
                        },
                    },
                },
            },

            fontStyle: {
                type: ControlType.Object,
                title: "Fonts",
                controls: {
                    font: {
                        type: ControlType.Font,
                        controls: "extended",
                    },

                    color: {
                        type: ControlType.Color,
                        title: "Color",
                        defaultValue: "#000000",
                    },

                    labelStyle: {
                        type: ControlType.Object,
                        title: "Label",
                        controls: {
                            font: {
                                type: ControlType.Font,
                                controls: "extended",
                            },

                            color: {
                                type: ControlType.Color,
                                title: "Color",
                                defaultValue: "#828282",
                            },

                            padding: {
                                type: ControlType.FusedNumber,
                                title: "Padding",
                                defaultValue: 0,
                                toggleKey: "isMixed",
                                toggleTitles: ["All", "Individual"],
                                valueKeys: [
                                    "paddingTop",
                                    "paddingRight",
                                    "paddingBottom",
                                    "paddingLeft",
                                ],
                                valueLabels: ["T", "R", "B", "L"],
                                min: 0,
                            },
                            textAlign: {
                                type: ControlType.Enum,
                                title: "Text Align",
                                options: ["left", "center", "right", "justify"],
                                optionTitles: [
                                    "Left",
                                    "Center",
                                    "Right",
                                    "Justify",
                                ],
                                defaultValue: "left",
                            },
                        },
                    },
                },
            },

            checkboxStyle: {
                type: ControlType.Object,
                title: "Checkbox/Radio/Consent",
                controls: {
                    font: {
                        type: ControlType.Font,
                        controls: "extended",
                    },
                    color: {
                        type: ControlType.Color,
                        title: "Label color",
                        defaultValue: "#000000",
                    },

                    size: {
                        type: ControlType.Number,
                        title: "Size",
                        defaultValue: 14,
                        min: 0,
                        step: 1,
                    },

                    colorScheme: {
                        type: ControlType.Enum,
                        title: "Mode",
                        options: ["light", "dark"],
                        optionTitles: ["Light", "Dark"],
                        defaultValue: "light",
                        displaySegmentedControl: true,
                        segmentedControlDirection: "horizontal",
                    },
                    accent: {
                        type: ControlType.Color,
                        title: "Active color",
                        defaultValue: "#2A00F7",
                    },

                    linkColor: {
                        type: ControlType.Color,
                        title: "Link color for consent",
                        defaultValue: "blue",
                    },

                    backgroundColor: {
                        type: ControlType.Color,
                        title: "Background color",
                        defaultValue: "#ffffff",
                    },

                    border: {
                        type: ControlType.Enum,
                        title: "Border",
                        options: ["solid", "none"],
                        optionTitles: ["Solid", "None"],
                        defaultValue: "solid",
                        displaySegmentedControl: true,
                        segmentedControlDirection: "horizontal",
                    },
                    borderWidth: {
                        type: ControlType.Number,
                        title: "Border Width",
                        defaultValue: 1,
                        hidden: (props) => props.border === "none",
                    },

                    borderColor: {
                        type: ControlType.Color,
                        title: "Border Color",
                        defaultValue: "#dddddd",
                        hidden: (props) => props.border === "none",
                    },

                    borderRadius: {
                        type: ControlType.Number,
                        title: "Border Radius",
                        defaultValue: 10,
                    },

                    padding: {
                        type: ControlType.FusedNumber,
                        title: "Padding",
                        defaultValue: 0,
                        toggleKey: "isMixed",
                        toggleTitles: ["All", "Individual"],
                        valueKeys: [
                            "paddingTop",
                            "paddingRight",
                            "paddingBottom",
                            "paddingLeft",
                        ],
                        valueLabels: ["T", "R", "B", "L"],
                        min: 0,
                    },
                    marginBottom: {
                        type: ControlType.Number,
                        title: "Space between",
                        defaultValue: 4,
                    },

                    flexDirection: {
                        type: ControlType.Enum,
                        title: "flexDirection",
                        options: ["column", "row"],
                        optionTitles: ["Column", "Row"],
                        defaultValue: "column",
                        displaySegmentedControl: true,
                        segmentedControlDirection: "horizontal",
                    },
                    showActiveBorder: {
                        type: ControlType.Boolean,
                        title: "Show active style",
                        defaultValue: true,
                        description:
                            "This will place a larger outline around the input when selected/hovered/error",
                    },
                },
            },

            fileStyle: {
                type: ControlType.Object,
                title: "File style",
                controls: {
                    boldText: {
                        type: ControlType.String,
                        title: "Text in bold",
                        defaultValue: "Click to upload files",
                    },
                    regularText: {
                        type: ControlType.String,
                        title: "Text regular",
                        defaultValue: "or drag and drop",
                    },
                    file_plural: {
                        type: ControlType.String,
                        title: "File multiple",
                        defaultValue: "files max.",
                    },
                    file_single: {
                        type: ControlType.String,
                        title: "File single",
                        defaultValue: "file",
                    },

                    iconColor: {
                        type: ControlType.Color,
                        title: "Icon color",
                        defaultValue: "#000000",
                    },

                    backgroundColor: {
                        type: ControlType.Color,
                        title: "Icon bg color",
                        defaultValue: "#ffffff",
                    },

                    padding: {
                        type: ControlType.FusedNumber,
                        title: "Padding",
                        defaultValue: 0,
                        toggleKey: "isMixed",
                        toggleTitles: ["All", "Individual"],
                        valueKeys: [
                            "paddingTop",
                            "paddingRight",
                            "paddingBottom",
                            "paddingLeft",
                        ],
                        valueLabels: ["T", "R", "B", "L"],
                        min: 0,
                    },

                    showSelectedFiles: {
                        type: ControlType.Boolean,
                        title: "Show selected style",
                        defaultValue: true,
                        description:
                            "This will show the selected files below the input",
                    },
                },
            },
            npsStyle: {
                type: ControlType.Object,
                title: "NPS style",
                controls: {
                    color: {
                        type: ControlType.Color,
                        title: "Text color",
                        defaultValue: "#828282",
                    },

                    selectedColor: {
                        type: ControlType.Color,
                        title: "Selected textcolor",
                        defaultValue: "#000000",
                    },

                    fontSize: {
                        type: ControlType.Number,
                        title: "fontSize",
                        defaultValue: 12,
                        min: 0,
                        step: 1,
                    },

                    backgroundColor: {
                        type: ControlType.Color,
                        title: "bg color",
                        defaultValue: "#ffffff",
                    },
                    selectedBGColor: {
                        type: ControlType.Color,
                        title: "Selected bg color",
                        defaultValue: "#eee",
                    },

                    border: {
                        type: ControlType.Enum,
                        title: "Border",
                        options: ["solid", "none"],
                        optionTitles: ["Solid", "None"],
                        defaultValue: "solid",
                        displaySegmentedControl: true,
                        segmentedControlDirection: "horizontal",
                    },
                    borderWidth: {
                        type: ControlType.Number,
                        title: "Border Width",
                        defaultValue: 1,
                        hidden: (props) => props.border === "none",
                    },

                    borderColor: {
                        type: ControlType.Color,
                        title: "Border Color",
                        defaultValue: "#dddddd",
                        hidden: (props) => props.border === "none",
                    },

                    selectedBorderColor: {
                        type: ControlType.Color,
                        title: "Selected Border Color",
                        defaultValue: "#000",
                        hidden: (props) => props.border === "none",
                    },

                    borderRadius: {
                        type: ControlType.Number,
                        title: "Border Radius",
                        defaultValue: 10,
                    },
                    size: {
                        type: ControlType.Number,
                        title: "Size",
                        defaultValue: 24,
                    },
                    showActiveBorder: {
                        type: ControlType.Boolean,
                        title: "Show active style",
                        defaultValue: true,
                        description:
                            "This will place a larger outline around the input when selected/hovered/error",
                    },
                },
            },

            rangePicker: {
                type: ControlType.Object,
                title: "Range Picker",
                controls: {
                    knobSize: {
                        type: ControlType.Number,
                        title: "Knob Size",
                        defaultValue: 16,
                    },

                    trackHeight: {
                        type: ControlType.Number,
                        title: "Track Height",
                        defaultValue: 8,
                    },

                    knobColor: {
                        type: ControlType.Color,
                        title: "Knob Color",
                        defaultValue: "#2A00F7",
                    },

                    trackColor: {
                        type: ControlType.Color,
                        title: "Track Color",
                        defaultValue: "#eeeeee",
                    },

                    font: {
                        type: ControlType.Font,
                        controls: "extended",
                    },
                    color: {
                        type: ControlType.Color,
                        title: "Text color",
                        defaultValue: "#000000",
                    },

                    min: {
                        type: ControlType.Number,
                        title: "Min value",
                        defaultValue: 0,
                    },
                    max: {
                        type: ControlType.Number,
                        title: "Max value",
                        defaultValue: 100,
                    },
                    defaultValue: {
                        type: ControlType.Number,
                        title: "Default value",
                        defaultValue: 50,
                        description:
                            "Best to set this in the middle of min/max value",
                    },
                    marginTop: {
                        type: ControlType.Number,
                        title: "Margin Top",
                        defaultValue: 0,
                    },
                    marginBottom: {
                        type: ControlType.Number,
                        title: "Margin Bottom",
                        defaultValue: 0,
                    },
                },
            },
        },
    },

    buttonStyle: {
        type: ControlType.Object,
        title: "Submit button",
        controls: {
            buttonSubmit: {
                type: ControlType.String,
                title: "Submit text",
                defaultValue: "Submit",
            },

            buttonNext: {
                type: ControlType.String,
                title: "Next step text",
                defaultValue: "Continue",
            },
            loaderSubmit: {
                type: ControlType.String,
                title: "Waiting text",
                defaultValue: "Just a sec",
            },
            showLoadingDots: {
                type: ControlType.Boolean,
                title: "Show waiting state",
                defaultValue: true,
                description:
                    "This will display a animation on submit that serves as waiting state on submit",
            },

            width: {
                type: ControlType.Enum,
                title: "Button width",
                options: ["100%", "fit-content"],
                optionTitles: ["Fill", "fit-content"],
                defaultValue: "100%",
            },

            buttonAlignment: {
                type: ControlType.Enum,
                options: ["flex-start", "center", "flex-end"],
                defaultValue: "center",
                title: "Button Alignment",
            },
            styling: {
                type: ControlType.Object,
                title: "Styling",
                controls: {
                    borderRadius: {
                        type: ControlType.Number,
                        title: "Border Radius",
                        defaultValue: 10,
                    },
                    backgroundColor: {
                        type: ControlType.Color,
                        title: "Background Color",
                        defaultValue: "#2A00F7",
                    },
                    borderColor: {
                        type: ControlType.Color,
                        title: "Border Color",
                        defaultValue: "#2A00F7",
                    },
                    borderWidth: {
                        type: ControlType.Number,
                        title: "Border Width",
                        defaultValue: 1,
                    },
                    padding: {
                        type: ControlType.FusedNumber,
                        title: "Padding",
                        defaultValue: 16,
                        toggleKey: "isMixed",
                        toggleTitles: ["All", "Individual"],
                        valueKeys: [
                            "paddingTop",
                            "paddingRight",
                            "paddingBottom",
                            "paddingLeft",
                        ],
                        valueLabels: ["T", "R", "B", "L"],
                        min: 0,
                    },

                    marginTop: {
                        type: ControlType.Number,
                        title: "Margin Top",
                        defaultValue: 8,
                    },

                    hoverColor: {
                        type: ControlType.Color,
                        title: "Hover",
                        defaultValue: "#522AFF",
                    },

                    showIcon: {
                        type: ControlType.Boolean,
                        title: "Show Icon",
                        defaultValue: true,
                    },
                },
            },
            fontStyle: {
                type: ControlType.Object,
                title: "Font",
                controls: {
                    font: {
                        type: ControlType.Font,
                        controls: "extended",
                    },
                    color: {
                        type: ControlType.Color,
                        title: "Color",
                        defaultValue: "#ffffff",
                    },
                },
            },
        },
    },

    success: {
        type: ControlType.Object,
        title: "Success",
        controls: {
            afterSubmit: {
                title: "On Success",
                type: ControlType.Enum,
                options: ["redirect", "toast"],
                optionTitles: [
                    "Redirect to another page",
                    "Show a toast message",
                ],
                defaultValue: "toast",
            },

            timeout: {
                type: ControlType.Enum,
                title: "Set Time-out",
                options: ["1000", "3000", "5000", "infinite"],
                optionTitles: [
                    "1 second",
                    "3 seconds",
                    "5 seconds",
                    "Infinite",
                ],
                defaultValue: "3000",
                hidden: (props) => props.afterSubmit !== "toast",
            },

            redirectURL: {
                title: "Redirect URL",
                type: ControlType.Link,

                hidden: (props) => props.afterSubmit !== "redirect",
            },
            appendQueryParams: {
                title: "Append Query Params",
                type: ControlType.Boolean,
                defaultValue: true, // or true, depending on what you want the default behavior to be
                enabledTitle: "Yes",
                disabledTitle: "No",
                hidden: (props) => props.afterSubmit !== "redirect",
                description:
                    "Adding query parameters to your redirect URL provides the formdata in your redirect URL, typically used for tracking or configuration purposes.",
            },

            messageTitle: {
                type: ControlType.String,
                title: "Title",
                defaultValue: "Thank you", // set default text
                hidden: (props) => props.success.afterSubmit !== "toast",
            },
            messageText: {
                type: ControlType.String,
                title: "Body",
                defaultValue: "We will be in touch shortly", // set default text
                hidden: (props) => props.success.afterSubmit !== "toast",
            },

            toastIcon: {
                type: ControlType.Number,
                title: "Size icon",
                defaultValue: 32,
            },

            iconColor: {
                type: ControlType.Color,
                title: "Color icon",
                defaultValue: "#2A00F7", // set default color
                hidden: (props) => props.success.afterSubmit !== "toast",
            },
            toastFontTitle: {
                type: ControlType.Object,
                title: "Title font",
                controls: {
                    font: {
                        type: ControlType.Font,
                        controls: "extended",
                    },
                    color: {
                        type: ControlType.Color,
                        title: "Color",
                        defaultValue: "#000000",
                    },
                },
            },
            toastFontText: {
                type: ControlType.Object,
                title: "Text font",
                controls: {
                    font: {
                        type: ControlType.Font,
                        controls: "extended",
                    },
                    color: {
                        type: ControlType.Color,
                        title: "Color",
                        defaultValue: "#000000",
                    },
                },
            },
        },
    },
    testStep: {
        type: ControlType.Number,
        title: "Preview Step",
        defaultValue: 1, // assuming your steps start at 1
        min: 1, // minimum step value
        max: 20, // maximum step value or the highest step you have
        displayStepper: true, // optional: shows a stepper control in the UI
        description: "Always set to 1 in production",
    },
})
